import { inject } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { init } from '@stores/legal-pages/legal-pages.actions';
import { partnerId } from '@stores/partner/partner.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { LegalPageWebservice } from '@webservices/legal-pages/legal-page.webservice';
import { map, switchMap } from 'rxjs';

export const loadLegalPages = createEffect(
  (actions$ = inject(Actions), legalPageWebservice = inject(LegalPageWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => selectIfDefined(store, partnerId)),
      switchMap((id) => legalPageWebservice.getLegalPages(id)),
      map((legalPages) => init({ legalPages }))
    ),
  { functional: true }
);
