import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  serviceConfigAlreadyExists,
  serviceConfigurationUpdated,
  updateServiceConfiguration,
} from '@stores/service-configuration/service-configuration.actions';
import { serviceConfiguration } from '@stores/service-configuration/service-configuration.selectors';
import { ServicesV3WebService } from '@webservices/services/services.webservice';
import { map, of, switchMap } from 'rxjs';

export const updateServiceConfigurationEffect = createEffect(
  (actions$ = inject(Actions), servicesV3WebService = inject(ServicesV3WebService), store = inject(Store)) =>
    actions$.pipe(
      ofType(updateServiceConfiguration),
      switchMap(({ serviceId }) =>
        store.select(serviceConfiguration(serviceId)).pipe(map((configuration) => ({ configuration, serviceId })))
      ),
      switchMap(({ configuration, serviceId }) => {
        if (configuration) return of(serviceConfigAlreadyExists({ serviceId }));

        return servicesV3WebService
          .getServiceConfiguration(serviceId)
          .pipe(map((configuration) => serviceConfigurationUpdated({ serviceId, configuration })));
      })
    ),
  { functional: true }
);
