import { Component, computed, inject } from '@angular/core';
import { LoginSigninButtonsService } from '@domains/login-signin-buttons/login-signin-buttons.service';
import { Store } from '@ngrx/store';
import { selectPartner } from '@stores/partner/partner.selectors';

@Component({
  selector: 'app-login-signin-buttons',
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    <nav aria-label="Activation et connexion" role="navigation">
      <ul class="login-actions">
        @if (signUp(); as signUp) {
          <li>
            <a [href]="signUp.url" [target]="signUp.target" obendy-button appearance="outline">
              {{ signUp.label }}
            </a>
          </li>
        }
        @if (signIn(); as signIn) {
          <li>
            <a [href]="signIn.url" [target]="signIn.target" obendy-button>
              {{ signIn.label }}
            </a>
          </li>
        }
      </ul>
    </nav>
  `,
  styles: [
    `
      .login-actions {
        display: flex;
        gap: 1rem;
        align-items: center;
      }
    `,
  ],
  providers: [LoginSigninButtonsService],
  standalone: true,
})
export class LoginSigninButtonsComponent {
  readonly #store = inject(Store);
  readonly service = inject(LoginSigninButtonsService);

  readonly #partner = this.#store.selectSignal(selectPartner);

  readonly signIn = computed(() => this.service.getSigninCta(this.#partner()));
  readonly signUp = computed(() => this.service.getSignupCta(this.#partner()));
}
