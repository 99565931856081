interface TimeDiff {
  seconds: number;
  minutes: number;
  hours: number;
  months: number;
  days: number;
  years: number;
  isFuture?: boolean;
}

type TimeDiffGenerator = (diff: TimeDiff) => string;

export const defaultTimeDiffGenerator: TimeDiffGenerator = (diff: TimeDiff): string => {
  if (diff.isFuture) {
    return 'Dans le futur';
  } else {
    if (diff.seconds <= 10) {
      return 'Il y a quelques secondes';
    }

    if (diff.seconds <= 59) {
      return `Il y a moins d'une minute`;
    }

    if (diff.minutes <= 1) {
      return `Il y a une minute`;
    }

    if (diff.minutes <= 10) {
      return `Il y a quelques minutes`;
    }

    if (diff.minutes <= 59) {
      return `Il y a moins d'une heure`;
    }

    if (diff.hours <= 1) {
      return `Il y a une heure`;
    }

    if (diff.hours <= 23) {
      return `Il y a ${diff.hours} heures`;
    }

    if (diff.hours <= 36) {
      return `Il y a un jour`;
    }

    if (diff.days <= 25) {
      return `Il y a ${diff.days} jours`;
    }

    if (diff.days <= 45) {
      return `Il y a plus d'un mois`;
    }

    if (diff.days <= 356) {
      return `Il y a ${diff.months} mois`;
    }

    if (diff.days <= 545) {
      return `Il y a plus d'un an`;
    }

    return `Il y a ${diff.years} ans`;
  }
};

export const createTimeDiff = (seconds: number): TimeDiff => {
  const isFuture = seconds < 0;

  if (isFuture) {
    seconds = Math.abs(seconds);
  }

  const diff: Partial<TimeDiff> = { seconds, isFuture };

  diff.minutes = Math.round(seconds / 60);
  diff.hours = Math.round(diff.minutes / 60);
  diff.days = Math.round(diff.hours / 24);
  diff.months = Math.round(diff.days / 30.416);
  diff.years = Math.round(diff.days / 365);

  return diff as TimeDiff;
};
