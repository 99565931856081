import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DynamicIllustration } from '@commons/interservice-card/directives/dynamic-illustration.directive';
import { LinkTargetPipe } from '@commons/pipes/links-target-blank/links-target-blank.pipe';
import { Store } from '@ngrx/store';
import * as FromPartnerSelectors from '@stores/partner/partner.selectors';
import { trackEvent } from '@wizbii/utils/tracking';

@Component({
  selector: 'app-co-platform-main-menu-card',
  imports: [MatIconModule, DynamicIllustration, LinkTargetPipe],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->

    @if (coPlatformMainMenuConfig(); as config) {
      <div class="burger-menu-card" [illustrationSrc]="config.backgroundPictureUrl" dynamicIllustration>
        <a
          class="burger-menu-card__link"
          [class.burger-menu-card__link--with-icon]="config.logoPictureUrl"
          [href]="config.link"
          [target]="'_blank' | linkTarget"
          (click)="trackEvent('retour plateforme mère', 'click', 'bandeau menu')"
          rel="noopener noreferrer"
        >
          @if (config.logoPictureUrl) {
            <img class="burger-menu-card__link__logo" [src]="config.logoPictureUrl" alt="" width="126" height="150" />
          }
          <p class="burger-menu-card__link__description">
            {{ config.description }}
          </p>
          <mat-icon class="burger-menu-card__cta" svgIcon="arrow-left" color="white" />
        </a>
      </div>
    }
  `,
  styles: [
    `
      .burger-menu-card {
        --burger-menu-card-color: white;

        position: relative;
        display: grid;
        grid-template-columns: minmax(0, 1fr);
        border-radius: 8px;
        padding: 1rem;
        transition: filter 300ms;
        overflow: clip;
        filter: drop-shadow(0px 8px 16px rgb(0 0 0 / 0.24));
        z-index: 1; /* Ensure the card content is above the pseudo-elements */

        &::before {
          position: absolute;
          content: '';
          inset: 0;
          background: linear-gradient(
            rgba(34, 35, 51, 0.2),
            rgba(34, 35, 51, 0.2)
          ); /* darken a bit the background image only */
          border-radius: 8px;
          z-index: 0;
        }

        &::after {
          position: absolute;
          content: '';
          inset: 0;
          background-image: var(--illustration-url);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          border-radius: 8px;
          z-index: -1;
        }

        & > * {
          position: relative;
          z-index: 1;
        }

        &:hover {
          filter: drop-shadow(0px 16px 24px rgb(0 0 0 / 0.32));
        }

        &__link {
          display: grid;
          gap: 0.6875rem;
          align-items: center;
          text-decoration: none;

          &__description {
            --obendy-text-font-size: 0.625rem;
            --obendy-text-color: var(--burger-menu-card-color);
            --obendy-text-regular-font-weight: 500;
          }

          &__logo {
            width: 2.25rem;
            height: 2.625rem;
          }

          &--with-icon {
            grid-template-columns: auto minmax(0, 1fr) auto;
            color: var(--burger-menu-card-color);
          }
        }

        &__cta {
          transform: scale(-1, -1);
          justify-self: center;
        }
      }
    `,
  ],
  standalone: true,
})
export class CoPlatformMainMenuCardComponent {
  protected coPlatformMainMenuConfig = inject(Store).selectSignal(FromPartnerSelectors.coPlatformMainMenuConfig);

  readonly trackEvent = trackEvent;
}
