import { Injectable, Renderer2, inject, signal } from '@angular/core';
import { trackEvent } from '@wizbii/utils/tracking';

type WindowWithFreshdesk = Window & {
  fwSettings: {
    widget_id: number;
    locale: 'fr';
  };
  FreshworksWidget: any;
};

@Injectable()
export class FreshdeskButtonService {
  readonly #renderer = inject(Renderer2);

  readonly trackEvent = trackEvent;

  readonly widgetIsOpen = signal<boolean>(false);
  readonly widgetIsMounted = signal<boolean>(false);

  get #window(): WindowWithFreshdesk {
    return window as any as WindowWithFreshdesk;
  }

  readonly toggleFreshworksWidget = (widgetIsOpen: boolean) => {
    if (widgetIsOpen) {
      this.trackEvent('widget freshdesk', 'click', 'CTA - Help - open');
    }

    this.#window.FreshworksWidget(widgetIsOpen ? 'open' : 'close');
  };

  readonly toggleWidget = () => this.widgetIsOpen.update((status) => !status);

  readonly initialize = (widgetId: number) => {
    this.#window.fwSettings = {
      widget_id: widgetId,
      locale: 'fr',
    };

    this.injectFreshWorksWidgetInWindowObject();

    const body = document.querySelector('body');
    if (body === null) {
      return;
    }

    const script = this.createFreshdeskElement(widgetId);

    this.#renderer.appendChild(body, script);
    this.widgetIsMounted.set(true);
  };

  readonly createFreshdeskElement = (widgetId: number): HTMLScriptElement => {
    const script = this.#renderer.createElement('script');

    script.id = 'Freshchat-js-sdk';
    script.async = true;
    script.src = `https://widget.freshworks.com/widgets/${widgetId}.js`;
    script.onload = () => {
      try {
        this.#window.FreshworksWidget();
      } catch (error) {
        console.log('error.: ', error);
      }
    };
    script.onerror = function (event: any) {
      console.log('error Onerror.: ', event);
    };

    return script;
  };

  readonly injectFreshWorksWidgetInWindowObject = () => {
    this.#window.FreshworksWidget ||
      (function () {
        if ('function' !== typeof (window as any).FreshworksWidget) {
          let n = function () {
            (n as any)['q'].push(arguments);
          };
          ((n as any)['q'] = []), ((window as any).FreshworksWidget = n);
        }
      })();
  };
}
