import { CurrencyCode, CurrencyCodeEnum, CurrencyEnum } from '@loyalty-v3/libs';
import { FIRST_PURCHASE, RENEWAL } from '@models/payment/payment-context';
import type { PurchasableArticle } from '@models/payment/purchasable-article';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentState, paymentFeatureKey } from '@stores/payment/payment.reducer';
import { selectPlan, userPlans } from '@stores/plan/plan.selectors';
import { selectProfile } from '@stores/profile/profile.selectors';
import dayjs from 'dayjs';

const getCurrencyCode = (currency: CurrencyEnum): CurrencyCodeEnum => CurrencyCode[currency];

const calculatePricePerMonth = (purchasableArticle: PurchasableArticle): number => {
  const valueWithTaxRate = purchasableArticle.amountPaid * (1 + purchasableArticle.taxRate / 100);
  const months = Math.floor(purchasableArticle.nbDayAccess / 30);

  return valueWithTaxRate / months / 100;
};

const calculateTotalPriceWithTaxed = (purchasableArticle: PurchasableArticle): number =>
  (purchasableArticle.unitPrice * (1 + purchasableArticle.taxRate / 100)) / 100;

const calculateEndDate = (date: string | null, durationInDays: number | null): string | null => {
  if (!durationInDays) {
    return null;
  }

  const _date = date === null ? dayjs() : dayjs(date);

  return _date.add(durationInDays, 'day').toISOString();
};

const selectPayment = createFeatureSelector<PaymentState>(paymentFeatureKey);

export const basket = createSelector(selectPayment, (state) => state?.basket);
export const billingInfos = createSelector(selectPayment, (state) => state?.billingInfos);
const purchasableArticles = createSelector(selectPayment, (state) => state?.purchasableArticles);
const context = createSelector(selectProfile, (state) =>
  state?.currentPlan.expirationDate === null ? FIRST_PURCHASE : RENEWAL
);

const purchasableArticle = createSelector(
  purchasableArticles,
  context,
  userPlans,
  selectProfile,
  (purchasableArticles, context, plans, profile) => {
    if (profile === null) {
      return null;
    }
    if (!plans) {
      return null;
    }

    const currentPlanLevel = plans.find((plan) => plan.id === profile?.currentPlan.planId)?.level ?? null;

    if (currentPlanLevel === null) {
      return null;
    }

    const articlesWithLevel = (purchasableArticles ?? []).map((article) => ({
      ...article,
      level: plans.find((plan) => plan.id === article.toPlanId)?.level ?? 0,
    }));

    // * If the user's current plan cannot be renewed,
    // * return the next available plan article with a level one higher than the current plan level
    if (context === FIRST_PURCHASE) {
      return articlesWithLevel.find((article) => currentPlanLevel + 1 === article.level) ?? null;
    }

    // * Return the current plan article
    if (context === RENEWAL) {
      return articlesWithLevel.find((article) => article.toPlanId === profile?.currentPlan.planId)!;
    }
    return null;
  }
);

const childrenPurchasableArticle = createSelector(
  purchasableArticles,
  selectProfile,
  selectPlan,
  (purchasableArticles, profile, planGroups) => {
    if (!purchasableArticles || purchasableArticles.length === 0) {
      return null;
    }

    if (!planGroups) {
      return null;
    }

    if (profile?.children.length === 0) {
      return null;
    }

    const planGroupId = profile?.children?.[0]?.planGroupId;
    const childrenPlanGroups = planGroups.childrenPlanGroups;

    if (!childrenPlanGroups || !planGroupId) {
      return null;
    }

    const purchasableArticleIdFromChildrenPlanGroups = childrenPlanGroups[planGroupId].plans.find(
      (plan) => plan.level === 0
    )?.upsells?.[0]?.articleId;

    return purchasableArticles.find((article) => article.id === purchasableArticleIdFromChildrenPlanGroups)!;
  }
);

export const purchasableArticleWithDisplayableValue = createSelector(
  purchasableArticle,
  childrenPurchasableArticle,
  context,
  selectProfile,
  (purchasableArticle, childrenPurchasableArticle, context, profile) => {
    if (profile === null) {
      return null;
    }

    const _purchasableArticle = purchasableArticle ?? childrenPurchasableArticle;

    if (!_purchasableArticle) {
      return null;
    }

    const expirationDate = profile?.currentPlan.expirationDate ?? null;

    const endDate = calculateEndDate(expirationDate, _purchasableArticle.nbDayAccess);

    return {
      ..._purchasableArticle,
      displayUnitPrice: _purchasableArticle.unitPrice / 100,
      displayTaxes: `${_purchasableArticle.taxRate}%`,
      pricePerMonth: calculatePricePerMonth(_purchasableArticle),
      durationInMonth: Math.floor(_purchasableArticle.nbDayAccess / 30), // attention au nombre de jours < 30
      totalPriceWithTaxes: calculateTotalPriceWithTaxed(_purchasableArticle),
      currencyCode: getCurrencyCode(_purchasableArticle.currency),
      isRenewable: context === RENEWAL,
      expirationDate,
      endDate,
    };
  }
);
