import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { BankingProductsService } from '@domains/bank-product/services/banking-products.service';
import { Store } from '@ngrx/store';
import { clearLegalNotices } from '@stores/legal-notices/legal-notices.actions';
import { legalNoticesEntries } from '@stores/legal-notices/legal-notices.selectors';
import { hostBinding } from 'ngxtension/host-binding';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-legal-notices',
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    @if (legalNoticesEntriesSig(); as legalNotices) {
      @if (legalNotices.length > 0) {
        <ol class="legal-notices">
          @if (legalNotices.length === 1) {
            <li [innerHTML]="legalNotices[0][1]" legalNoticeIndex="*"></li>
          } @else {
            @for (legalNotice of legalNotices; track legalNotice[0]; let index = $index) {
              <li [innerHTML]="legalNotice[1]" [attr.legalNoticeIndex]="index + 1"></li>
            }
          }
        </ol>
      }
    }
  `,
  styles: [
    `
      .legal-notices {
        background-color: rgb(var(--loyal-header-grey));
        padding: 1.25rem;
        border: 1px solid rgb(var(--loyal-border-light));
        border-radius: var(--loyal-border-radius);
        counter-reset: notices-list;

        > li {
          --obendy-text-font-size: 0.75rem;

          display: flex;
          margin-bottom: 0.4rem;
          list-style: none;
          position: relative;

          &:before {
            font-size: var(--obendy-text-font-size);
            content: '(' attr(legalNoticeIndex) ')';
            margin-right: 0.25rem;
          }
        }

        li:last-of-type {
          margin-bottom: 0;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LegalNoticesComponent {
  readonly #router = inject(Router);
  readonly #store = inject(Store);
  readonly #bankingProductsService = inject(BankingProductsService);

  readonly legalNoticesEntriesSig = this.#store.selectSignal(legalNoticesEntries);
  readonly noNoticesDisplay = hostBinding(
    'style.display',
    computed(() => (this.legalNoticesEntriesSig().length > 0 ? 'block' : 'none'))
  );

  constructor() {
    this.#router.events
      .pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof NavigationEnd),
        map((event) => (event as NavigationEnd).urlAfterRedirects)
      )
      .subscribe({
        next: (url) => {
          this.#bankingProductsService.setSelectedBankingProducts(url);
          this.#store.dispatch(clearLegalNotices());
        },
      });
  }
}
