import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginationHelper } from '@commons/utils/pagination';
import { environment } from '@environment';

@Injectable()
export class MatPaginatorAlgolia extends MatPaginatorIntl {
  readonly firstPageLabel = 'Première page';
  readonly previousPageLabel = 'Page précédente';
  readonly nextPageLabel = 'Page suivante';
  readonly lastPageLabel = 'Dernière page';

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (pageSize < 1) {
      return '';
    }

    const nbHits = Math.max(length, 0);

    const nbPages = PaginationHelper.getNbPages(
      nbHits,
      environment.pagination.max_hits,
      pageSize,
      environment.pagination.max_pages
    );

    return `${page + 1} sur ${nbPages}`;
  };
}
