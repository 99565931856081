import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { BreadcrumbsComponent } from '@commons/breadcrumbs/breadcrumbs.component';
import { LoginSigninButtonsComponent } from '@domains/login-signin-buttons/login-signin-buttons.component';

@Component({
  selector: 'app-breadcrumbs-header',
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    <header class="breadcrumbs-header" role="banner">
      <app-breadcrumbs class="breadcrumbs-header__breadcrumbs" />
      @if (!isLogged()) {
        <app-login-signin-buttons class="breadcrumbs-header__login-buttons" />
      }
    </header>
  `,
  styles: [
    `
      @use '@wizbii-components/styles/src/breakpoints' as breakpoints;

      .breadcrumbs-header {
        display: flex;
        align-items: center;

        &__breadcrumbs {
          flex-grow: 1;
        }

        &__login-buttons {
          display: none;

          @include breakpoints.minWidth('xl') {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BreadcrumbsComponent, LoginSigninButtonsComponent],
})
export class BreadcrumbsHeaderComponent {
  readonly isLogged = input(false);
}
