import { ErrorHandler, Injectable, InjectionToken, inject } from '@angular/core';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular/dist/esm2015';

export const BugsnagHandler = new InjectionToken<BugsnagErrorHandler>('BugsnagHandlerToken');

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  readonly #bugsnagHandler = inject(BugsnagHandler);

  handleError(error: any) {
    this.#bugsnagHandler.handleError(error);
  }
}
