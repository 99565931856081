import { Injectable, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MONEY_DASHBOARD_ROUTING } from '@domains/money/money-dashboard/money-dashboard.routing';
import { environment } from '@environment';
import { FEATURES_ROUTING } from '@features/features.routing';
import { BIG_FIVE_ROUTING } from '@features/services/big-five/big-five.routing';
import { DRIVE_ROUTING } from '@features/services/drive/drive.routing';
import { MONEY_ROUTING } from '@features/services/money/money.routing';
import { SERVICE_CONSENT_ROUTING } from '@features/services/service-consents-page-wrapper/service-consents.routing';
import { SERVICES_ROUTING } from '@features/services/services.routing';
import { Store } from '@ngrx/store';
import { isNpsEnabled, primaryColor, productName } from '@stores/partner/partner.selectors';
import { customerId } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { NpsWebservice } from '@webservices/nps/nps.webservice';
import { isLogged, userId } from '@wizbii-utils/angular/stores';
import deepEqual from 'deep-equal';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';

const SERVICES_WITH_PLAYER = [
  `/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.bigFive}/${BIG_FIVE_ROUTING.form}`,
  `/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.drive}/${DRIVE_ROUTING.play}`,
  `/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.drive}/${DRIVE_ROUTING.serie}`,
  `/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.drive}/${DRIVE_ROUTING.resume}`,
  `/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.money}/${MONEY_DASHBOARD_ROUTING.simulation}/${MONEY_ROUTING.eligibilityPlayer}`,
  `/${FEATURES_ROUTING.services}/${SERVICES_ROUTING.money}/${MONEY_DASHBOARD_ROUTING.simulation}/${MONEY_ROUTING.complementaryPlayer}`,
];

const EXCLUDE_NPS_MODAL_URLS = [
  `/${FEATURES_ROUTING.sign}`,
  `/${FEATURES_ROUTING.resetPassword}`,
  `/${FEATURES_ROUTING.expiredPassword}`,
  `/${FEATURES_ROUTING.redirect}`,
  `/${SERVICE_CONSENT_ROUTING.consent}`,
  ...SERVICES_WITH_PLAYER,
];

@Injectable({ providedIn: 'root' })
export class NpsCoreService {
  readonly #store = inject(Store);
  readonly #router = inject(Router);
  readonly #npsWebservice = inject(NpsWebservice);

  readonly initNPS = (): void => {
    combineLatest([
      selectIfDefined(this.#store, customerId),
      selectIfDefined(this.#store, isLogged),
      selectIfDefined(this.#store, productName),
      selectIfDefined(this.#store, userId),
      selectIfDefined(this.#store, isNpsEnabled),
      selectIfDefined(this.#store, primaryColor),
      this.#router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map((event) => (event as NavigationEnd).urlAfterRedirects)
      ),
    ])
      .pipe(
        distinctUntilChanged((a, b) => deepEqual(a, b)),
        filter(([_customerId, _isLogged, _productName, _userId, _isNpsEnabled, _primaryColor, routerUrl]) => {
          if (this.#shouldExcludeNpsModal(routerUrl)) {
            window['WizbiiNps' as keyof Window].widget.close();
            return false;
          }
          return true;
        }),
        filter(
          ([_customerId, isLogged, productName, userId, isNpsEnabled, primaryColor]) =>
            Boolean(isLogged) &&
            Boolean(productName) &&
            Boolean(userId) &&
            Boolean(isNpsEnabled) &&
            Boolean(primaryColor)
        ),
        distinctUntilChanged((a, b) => deepEqual(a, b)),
        switchMap(([customerId, _isLogged, productName, userId, _isNpsEnabled, primaryColor]) =>
          combineLatest([
            of(productName),
            of(userId),
            of(primaryColor),
            this.#npsWebservice.shouldDisplayNps(customerId).pipe(distinctUntilChanged()),
          ])
        ),
        distinctUntilChanged((a, b) => deepEqual(a, b)),
        filter(([_productName, _userId, _primaryColor, nps]) => nps.enabled)
      )
      .subscribe({
        next: ([productName, userId, primaryColor]) => {
          window['WizbiiNps' as keyof Window].widget.close();

          const config = {
            bgColor: 'white',
            textColor: '#222333',
            buttonFgColor: 'white',
            buttonBgColor: primaryColor,
            textSecondaryColor: '#222333',
            productName,
            userId,
            logoUrl: 'https://storage.googleapis.com/wizbii-images/loyalty/loyalty-v3/illustrations/success.svg',
            surveyId: 'nps_survey_obendy',
            wizbiiEnvFqdn: environment.apiDomain,
            jwtCookieName: environment.cookieTokenName,
            appId: environment.applicationId,
            sendToSupport: true,
          };

          window['WizbiiNps' as keyof Window].widget.init(config);
        },
      });

    this.#addEventListenerClose();
  };

  readonly #addEventListenerClose = () => {
    addEventListener('close-nps-widget', () => {
      selectIfDefined(this.#store, customerId)
        .pipe(
          take(1),
          switchMap((customerId) => this.#npsWebservice.markNpsAsClosed(customerId))
        )
        .subscribe();
    });
  };

  readonly #shouldExcludeNpsModal = (routerUrl: string): boolean => {
    return EXCLUDE_NPS_MODAL_URLS.some((url) => routerUrl.includes(url));
  };
}
