import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ExternalLinkIconComponent } from '@commons/external-link-icon/external-link-icon.component';
import { LinkTargetPipe } from '@commons/pipes/links-target-blank/links-target-blank.pipe';
import { Store } from '@ngrx/store';
import * as FromPartnerSelectors from '@stores/partner/partner.selectors';
import { trackEvent } from '@wizbii/utils/tracking';

@Component({
  selector: 'app-incentive-banner',
  standalone: true,
  imports: [ExternalLinkIconComponent, LinkTargetPipe],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->
    <!-- eslint-disable @angular-eslint/template/no-inline-styles -->
    @if (coPlatformIncentiveConfig(); as config) {
      <header class="incentive-banner" [style.--background-image]="'url(' + config.backgroundPictureUrl + ')'">
        <p class="incentive-banner__label">
          {{ config.description }}
        </p>
        <a
          class="incentive-banner__link"
          [href]="config.callToAction.url"
          [target]="config.callToAction.target | linkTarget"
          (click)="trackEvent('retour plateforme mère', 'click', 'incentive dashboard')"
          rel="noreferrer noopener"
          >{{ config.callToAction.label }} <app-external-link-icon />
        </a>
      </header>
    }
  `,
  styles: [
    `
      @use '@wizbii-components/styles/src/breakpoints' as breakpoints;
      @use 'mixins/animations' as animation;

      .incentive-banner {
        @include animation.fade-in;

        --incentive-white: #ffffff;
        --obendy-text-color: var(--incentive-white);
        --obendy-text-regular-font-weight: 500;

        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;
        padding: var(--padding, 1rem);
        flex-direction: var(--flex-direction, column);
        height: var(--height, fit-content);
        width: 100%;
        overflow: clip;
        background: var(--background-image);
        z-index: 10;
        position: var(--position, unset);

        &::before {
          content: '';
          position: absolute;
          filter: blur(3px) brightness(0.5);
          z-index: -1;
        }

        &__label {
          text-wrap: balance;
          z-index: 1;
          text-align: center;
        }

        &__link {
          z-index: 1;
          border-radius: 8px;
          border: 1px solid var(--incentive-white);
          color: var(--incentive-white);
          flex-shrink: 0;
          font-weight: 600;
          padding-block: 0.25rem;
          padding-inline: 0.5rem;
          text-decoration: none;
          transition:
            background-color ease-in-out 300ms,
            filter ease-in-out 300ms;

          &:hover,
          &:focus {
            border-color: transparent;
            background-color: rgb(var(--loyal-primary));
            filter: drop-shadow(0 8px 16px rgb(0 0 0 / 0.24));
          }
        }

        @include breakpoints.minWidth('xl') {
          --flex-direction: row;
          --height: 3rem;
          --padding: 0.5rem;
          --position: fixed;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncentiveBannerComponent {
  protected coPlatformIncentiveConfig = inject(Store).selectSignal(FromPartnerSelectors.coPlatformIncentiveConfig);

  readonly trackEvent = trackEvent;
}
