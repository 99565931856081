import { APP_INITIALIZER } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { onSettle } from '@bugsnag/browser-performance';

let globalRouterRef;
const bugsnagBootstrapper = {
    provide: APP_INITIALIZER,
    useFactory: (router) => {
        globalRouterRef = router;
        return () => { };
    },
    multi: true,
    deps: [Router]
};
class AngularRoutingProvider {
    resolveRoute(url) {
        var _a, _b;
        if (globalRouterRef) {
            let route = globalRouterRef.routerState.snapshot.root;
            let path = '';
            while (route.firstChild) {
                route = route.firstChild;
                if ((_a = route.routeConfig) === null || _a === void 0 ? void 0 : _a.matcher) {
                    path += '/<custom URL matcher>';
                }
                else {
                    path += '/' + ((_b = route.routeConfig) === null || _b === void 0 ? void 0 : _b.path);
                }
            }
            if (path) {
                return path;
            }
        }
        const base = document.baseURI.replace(window.origin, '');
        return url.pathname.replace(base, '') || '/';
    }
    listenForRouteChanges(startRouteChangeSpan) {
        // wait for the router to be available
        if (!globalRouterRef) {
            setTimeout(() => { this.listenForRouteChanges(startRouteChangeSpan); }, 50);
            return;
        }
        let navigation;
        globalRouterRef.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                const url = new URL(event.url, window.location.origin);
                navigation = {
                    url,
                    span: startRouteChangeSpan(url, event.navigationTrigger || 'routeChange')
                };
            }
            else if (navigation && (event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError)) {
                onSettle((endTime) => {
                    navigation === null || navigation === void 0 ? void 0 : navigation.span.end({ endTime, url: navigation.url });
                    navigation = undefined;
                });
            }
        });
    }
}

export { AngularRoutingProvider, bugsnagBootstrapper };
