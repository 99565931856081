import { AnimationTriggerMetadata, animate, style, transition, trigger } from '@angular/animations';

export function fadeEnter(timing: string): AnimationTriggerMetadata {
  return trigger('fadeEnterAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate(timing, style({ opacity: 1 }))]),
  ]);
}

export function fadeLeave(timing: string): AnimationTriggerMetadata {
  return trigger('fadeLeaveAnimation', [
    transition(':leave', [style({ opacity: 1 }), animate(timing, style({ opacity: 0 }))]),
  ]);
}
