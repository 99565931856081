import { inject } from '@angular/core';
import { Params, Router } from '@angular/router';
import { getLinkTarget } from '@commons/utils/platform';
import { FEATURES_ROUTING } from '@features/features.routing';
import { SERVICE_CONSENT_ROUTING } from '@features/services/service-consents-page-wrapper/service-consents.routing';
import { TargetEnum } from '@loyalty-v3/libs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom, tapResponse } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { serviceAuthorized } from '@stores/profile/profile.actions';
import { isServiceAuthorized, profileId } from '@stores/profile/profile.selectors';
import { selectQueryParams } from '@stores/router/router.selectors';
import { isServiceUnavailableInUserPlan } from '@stores/selectors/profile-services.selectors';
import {
  authorizeServiceAndRedirectAction,
  failServiceAuthorizationAndRedirectionAction,
  requestServiceAuthorizationToRedirectAction,
  resetServiceAuthorizationContextAction,
} from '@stores/service-authorization/authorization.actions';
import {
  FAILED_EXIT_ROUTER_LINK_OR_URL,
  SUCCESSFUL_EXIT_ROUTER_LINK_OR_URL,
  ServiceAuthorizationContext,
} from '@stores/service-authorization/authorization.reducer';
import { serviceAuthorizationContext } from '@stores/service-authorization/authorization.selectors';
import { serviceConfigurationConsentDisplayForm } from '@stores/service-configuration/service-configuration.selectors';
import { ProfileV3WebService } from '@webservices/profile/profile.webservice';
import { filter, map, switchMap, tap } from 'rxjs';

export const requestServiceAuthorizationToRedirectEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(requestServiceAuthorizationToRedirectAction),
      concatLatestFrom(({ context }) => [
        store.select(selectQueryParams),
        store.select(isServiceUnavailableInUserPlan(context.serviceId)),
        store.select(isServiceAuthorized(context.serviceId)),
        store.select(serviceConfigurationConsentDisplayForm(context.serviceId)),
      ]),
      tap(([{ context }, queryParams, serviceNotInUserPlan, serviceIsAuthorized, serviceRequiresConsents]) => {
        if (serviceNotInUserPlan === undefined || serviceRequiresConsents === undefined) {
          throw new Error('RequestSeviceAuthorizationToRedirect impossible, pre-requisite selectors not set.');
        }

        if (serviceNotInUserPlan) {
          navigateToUpsellPage(router);
          store.dispatch(resetServiceAuthorizationContextAction());
          return;
        }

        /**
          * Checking if service is authorized AND if user has consented
          *
          * IMPORTANT NOTE
          * At the moment of writing (May 2024), if a Service has been authorized (formerly "activated"), it means that the user has given consent to use the service. The user having given their consent, the Service is authorized.
          * This means that the `isServiceActivated` Selector (based on the `servicesAuthorizations` key) is used to check both if the service is authorized AND if the user has consented.

          * TODO
          * When backend and product are ready, we will need to check service authorizations and user consents separately.
          * We also will need to reset the user consents each time their content changes.
          * This means that user will be able to accept or deny updated consents.
          * This also means that the `isServiceActivated` Selector (and thus the `servicesAuthorizations` key) should not mean both that the service is authorized AND that the user has consented. Those notions must be different.
        */
        if (serviceIsAuthorized) {
          navigateToSuccessExit(router, context);
          store.dispatch(resetServiceAuthorizationContextAction());
          return;
        }

        if (serviceRequiresConsents) {
          navigateToUserConsentsForm(router, queryParams, context.replaceUrl, context.serviceId);
          return;
        }

        store.dispatch(authorizeServiceAndRedirectAction());
      })
    ),
  { functional: true, dispatch: false }
);

export const authorizeServiceAndRedirectEffect = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    router = inject(Router),
    profileWebservice = inject(ProfileV3WebService)
  ) =>
    actions$.pipe(
      ofType(authorizeServiceAndRedirectAction),
      concatLatestFrom(() => [store.select(serviceAuthorizationContext), store.select(profileId)]),
      map(([_, context, userId]) => [context, userId]),
      filter((data): data is [ServiceAuthorizationContext, string] => !!data[0] && !!data[1]),
      switchMap(([context, userId]) =>
        profileWebservice.authorizeService({ userId, serviceId: context.serviceId }).pipe(
          tapResponse({
            next: () => {
              navigateToSuccessExit(router, context);
              store.dispatch(serviceAuthorized({ serviceId: context.serviceId }));
              store.dispatch(resetServiceAuthorizationContextAction());
            },
            error: () => {
              navigateToFailExit(router, context);
              store.dispatch(resetServiceAuthorizationContextAction());
            },
          })
        )
      )
    ),
  { functional: true, dispatch: false }
);

export const failServiceAuthorizationAndRedirectionEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(failServiceAuthorizationAndRedirectionAction),
      concatLatestFrom(() => [store.select(serviceAuthorizationContext)]),
      map(([_, context]) => context),
      filter((context): context is ServiceAuthorizationContext => !!context),
      tap((context) => {
        navigateToFailExit(router, context);
        store.dispatch(resetServiceAuthorizationContextAction());
      })
    ),
  { functional: true, dispatch: false }
);

/** UTILS */

const navigateToFailExit = (router: Router, context: ServiceAuthorizationContext) => {
  const routerLink = context[FAILED_EXIT_ROUTER_LINK_OR_URL] ?? ['/', FEATURES_ROUTING.services, context.serviceId];
  router.navigate(routerLink);
};

const navigateToSuccessExit = (router: Router, context: ServiceAuthorizationContext) => {
  const routerLinkOrUrl = context[SUCCESSFUL_EXIT_ROUTER_LINK_OR_URL];

  const isExternal = !!routerLinkOrUrl && !Array.isArray(routerLinkOrUrl);
  if (isExternal) {
    openExternalUrl(routerLinkOrUrl, {
      queryParams: context.queryParams,
      target: context.target ?? TargetEnum.blank,
    });

    if (context.target === TargetEnum.blank) {
      router.navigate(['/', FEATURES_ROUTING.services, context.serviceId]);
    }

    return;
  }

  const routerLink = routerLinkOrUrl ? [...routerLinkOrUrl] : ['/', FEATURES_ROUTING.services, context.serviceId];

  if (context.target !== TargetEnum.blank) {
    router.navigate(routerLink, { queryParams: context.queryParams });
    return;
  }

  if (routerLink[0] === '/') routerLink.shift();

  openInternalUrl(routerLink.join('/'), { queryParams: context.queryParams, target: context.target });

  router.navigate(['/', FEATURES_ROUTING.services, context.serviceId]);
};

const navigateToUpsellPage = (router: Router) => router.navigate(['/', FEATURES_ROUTING.upsell]);

const navigateToUserConsentsForm = (
  router: Router,
  queryParams: Params,
  replaceUrl: boolean | undefined,
  serviceId: string
) => {
  router.navigate([FEATURES_ROUTING.services, serviceId, SERVICE_CONSENT_ROUTING.consent], {
    queryParams,
    queryParamsHandling: 'merge',
    replaceUrl: replaceUrl ?? false,
  });
};

const openExternalUrl = (url: string, extras: { queryParams?: Params; target: TargetEnum }) => {
  if (!url.toLowerCase().startsWith('https://'))
    throw new Error('Authorization - Exit URL does not start with ’https://’');

  const strictUrl = new URL(url);
  if (extras.queryParams) {
    for (const [key, value] of Object.entries(extras.queryParams)) {
      strictUrl.searchParams.append(key, value);
    }
  }

  window.open(strictUrl, getLinkTarget(window, extras.target), 'noreferrer noopener');
};

const openInternalUrl = (url: string, extras: { queryParams?: Params; target: TargetEnum }) => {
  if (extras.queryParams) {
    url = url.concat('?');

    for (const [key, value] of Object.entries(extras.queryParams)) {
      url = url.concat(key, '=', value, '&');
    }
  }

  window.open(url, getLinkTarget(window, extras.target), 'noreferrer noopener');
};
