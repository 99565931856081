import { inject } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { setServices } from '@stores/highlighted-service/highlighted-service.actions';
import { profileId } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { HighlightedServicesWebservice } from '@webservices/highlighted-services/highlighted-services.service';
import { map, switchMap } from 'rxjs';

export const loadHighlightedServices = createEffect(
  (
    actions$ = inject(Actions),
    highlightedServicesWebservice = inject(HighlightedServicesWebservice),
    store = inject(Store)
  ) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => selectIfDefined(store, profileId)),
      switchMap((profileId) => highlightedServicesWebservice.getHighlightedServices(profileId)),
      map((services) => setServices({ services }))
    ),
  { functional: true }
);
