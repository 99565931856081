import { NgClass, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { DesktopMenuButtonComponent } from '@commons/main-menu/desktop-main-menu/desktop-secondary-menu-button/desktop-menu-button.component';
import { MenuEntry, trackEntry } from '@commons/main-menu/service/entries';
import { LinkTargetPipe } from '@commons/pipes/links-target-blank/links-target-blank.pipe';
import { Store } from '@ngrx/store';
import { selectPartner } from '@stores/partner/partner.selectors';

@Component({
  selector: 'app-desktop-main-menu',
  template: `<!-- eslint-disable @angular-eslint/template/no-call-expression -->
    <nav
      class="tw-flex tw-items-center tw-justify-between tw-rounded-2xl tw-border tw-bg-white tw-px-8 tw-py-2 tw-shadow-default"
      role="navigation"
    >
      <div class="tw-flex tw-items-center tw-gap-x-8">
        @if (productLogo(); as productLogo) {
          <a [routerLink]="['/']" tabindex="-1">
            <span class="visually-hidden">Accueil</span>
            <img class="tw-h-16 tw-w-32" [ngSrc]="productLogo" height="64" width="128" alt="" placeholder imageLoader />
          </a>
        }

        @if (entries().length > 0) {
          <ul class="tw-flex tw-gap-x-6" role="menu">
            @for (entry of entries(); track $index) {
              <li class="tw-relative">
                @if (entry.children && entry.children.length === 0) {
                  @if (entry.isInternalLink) {
                    <a
                      class="tw-align-sub tw-no-underline before:tw-invisible before:tw-font-semibold before:tw-content-[attr(title)]"
                      [routerLink]="entry.route"
                      [title]="entry.label"
                      (click)="trackEntry(entry.route, 'menu-principal-desktop')"
                      #internalLinkActive="routerLinkActive"
                      role="menuitem"
                      routerLinkActive
                    >
                      <span
                        class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-max tw-whitespace-nowrap tw-text-base tw-font-medium tw-text-black hover:tw-font-semibold hover:tw-text-primary"
                        [ngClass]="{
                          'tw-font-semibold tw-text-primary': internalLinkActive.isActive,
                        }"
                      >
                        {{ entry.label }}
                      </span>
                    </a>
                  } @else {
                    <a
                      class="tw-align-sub tw-no-underline before:tw-invisible before:tw-font-semibold before:tw-content-[attr(title)]"
                      [href]="entry.route"
                      [target]="entry.target | linkTarget"
                      [title]="entry.label"
                      (click)="trackEntry(entry.route, 'menu-principal-desktop')"
                      #internalLinkActive="routerLinkActive"
                      role="menuitem"
                      routerLinkActive
                    >
                      <span
                        class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-whitespace-nowrap tw-text-base tw-font-medium tw-text-black hover:tw-font-semibold hover:tw-text-primary"
                        [ngClass]="{
                          'tw-font-semibold tw-text-primary': internalLinkActive.isActive,
                        }"
                      >
                        {{ entry.label }}
                      </span>
                    </a>
                  }
                } @else if (entry.children) {
                  <app-desktop-menu-button [mainMenuEntry]="entry" [childrenEntries]="entry.children" />
                }
              </li>
            }
          </ul>
        }
      </div>

      @if (secondaryEntries(); as secondaryEntries) {
        @if (secondaryEntries.length > 0) {
          <app-desktop-menu-button [childrenEntries]="secondaryEntries" />
        }
      }
    </nav> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, RouterLinkActive, NgOptimizedImage, DesktopMenuButtonComponent, NgClass, LinkTargetPipe],
})
export class DesktopMainMenuComponent {
  readonly entries = input.required<MenuEntry[]>();
  readonly secondaryEntries = input.required<MenuEntry[]>();

  readonly #partner = inject(Store).selectSignal(selectPartner);
  readonly productLogo = computed(() => {
    const partner = this.#partner();
    if (!partner) throw Error('Missing partner');

    return partner.partnerConfiguration.assets.logos.productLogo.url;
  });

  protected readonly trackEntry = trackEntry;
}
