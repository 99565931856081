import { inject } from '@angular/core';
import { PlanGroup } from '@loyalty-v3/libs';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { selectPlan } from '@stores/plan/plan.selectors';
import { init } from '@stores/themes/themes.actions';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { ThemesWebService } from '@webservices/themes.webservice';
import { map, switchMap } from 'rxjs';

const userIsLegalGuardianAndPlatformHasPayment = (userPlanGroup: PlanGroup): boolean =>
  userPlanGroup.relatedGroupId !== null;

export const loadThemes = createEffect(
  (actions$ = inject(Actions), themesWebService = inject(ThemesWebService), store = inject(Store)) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => selectIfDefined(store, selectPlan)),
      map((planGroups) => planGroups.userPlanGroup),
      switchMap((userPlanGroup) =>
        themesWebService.getThemesFromPlanGroupId(
          userIsLegalGuardianAndPlatformHasPayment(userPlanGroup) ? userPlanGroup.relatedGroupId! : userPlanGroup.id
        )
      ),
      map((themes) => init({ themes }))
    ),
  { functional: true }
);
