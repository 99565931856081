import { Basket } from '@models/payment/basket';
import { BillingInformations } from '@models/payment/billing-informations';
import { PaymentContext } from '@models/payment/payment-context';
import { PAYMENT_FAILED, PAYMENT_SUCCESS, PaymentStatus } from '@models/payment/payment-status';
import { BASKET_PREVIEW, PaymentStep } from '@models/payment/payment-steps';
import { PurchasableArticle } from '@models/payment/purchasable-article';
import { createReducer, on } from '@ngrx/store';
import {
  addItemToBasket,
  emptyBasket,
  goToStep,
  init,
  initPayment,
  paymentFailed,
  paymentReset,
  paymentSuccess,
  removeItemFromBasket,
  setBillingInfos,
  setInitialStep,
  setPurchasableArticle,
  setPurchasableArticlesFromPlanGroupIdSuccess,
} from '@stores/payment/payment.actions';

export const paymentFeatureKey = 'payment';

export interface PaymentState {
  basket: Basket | null;
  billingInfos: BillingInformations | null;
  paymentInitiated: boolean;
  paymentStatus: PaymentStatus;
  paymentStep: PaymentStep;
  purchasableArticles: PurchasableArticle[] | null;
  purchasableArticle: PurchasableArticle | null;
  context: PaymentContext | null;
}

const initialState: PaymentState = {
  billingInfos: null,
  paymentInitiated: false,
  purchasableArticles: null,
  purchasableArticle: null,
  paymentStatus: null,
  paymentStep: null,
  basket: null,
  context: null,
};

export const paymentReducer = createReducer(
  initialState,
  on(init, (state, { basket, paymentInitiated, paymentStep }) => ({ ...state, basket, paymentInitiated, paymentStep })),
  on(setInitialStep, (state) => ({ ...state, paymentStep: BASKET_PREVIEW })),
  on(initPayment, (state) => ({ ...state, paymentInitiated: true })),
  on(setBillingInfos, (state, { billingInformations }) => ({ ...state, billingInfos: billingInformations })),
  on(addItemToBasket, (state, { basketItem }) => {
    const doesItemExistInBasket = Boolean(
      (state.basket ?? []).find(
        (item) => item.articleId === basketItem.articleId && item.customerId === basketItem.customerId
      )
    );

    if (doesItemExistInBasket) {
      return state;
    }

    const updatedBasket = [...(state.basket ?? []), basketItem];
    return { ...state, basket: updatedBasket };
  }),
  on(removeItemFromBasket, (state, { basketItem }) => {
    const doesCustomerIdExistInBasket = Boolean(
      (state.basket ?? []).find((item) => item.customerId === basketItem.customerId)
    );

    if (!doesCustomerIdExistInBasket) {
      return state;
    }

    const updatedBasket = (state.basket ?? []).filter((item) => item.customerId !== basketItem.customerId);
    return { ...state, basket: updatedBasket };
  }),
  on(goToStep, (state, { paymentStep }) => ({ ...state, paymentStep })),
  on(setPurchasableArticle, (state, { purchasableArticle }) => ({ ...state, purchasableArticle })),
  on(paymentSuccess, (state) => ({ ...state, paymentStatus: PAYMENT_SUCCESS })),
  on(paymentReset, (state) => ({ ...state, paymentStatus: null })),
  on(paymentFailed, (state) => ({ ...state, paymentStatus: PAYMENT_FAILED })),
  on(emptyBasket, (state) => ({ ...state, basket: [] })),
  on(setPurchasableArticlesFromPlanGroupIdSuccess, (state, { purchasableArticles }) => ({
    ...state,
    purchasableArticles,
  }))
);
