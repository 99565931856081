import { Injectable, inject } from '@angular/core';
import { LegalStatusEnum, Profile } from '@loyalty-v3/libs';
import { BillingInformations } from '@models/payment/billing-informations';
import { Store } from '@ngrx/store';
import { setBillingInfos, setPurchasableArticlesFromPlanGroupId } from '@stores/payment/payment.actions';
import { profileBillingInfos, selectProfile } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { City } from '@wizbii/utils/models';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentCoreService {
  readonly #store = inject(Store);

  readonly initBillingInfos = () => {
    selectIfDefined(this.#store, profileBillingInfos)
      .pipe(
        filter(
          (data): data is { firstName: string; lastName: string; location: City } => !!data.firstName && !!data.lastName
        )
      )
      .subscribe({
        next: ({ location, firstName, lastName }) => {
          let billingInformations: BillingInformations = {
            firstName: '',
            lastName: '',
            address: '',
            city: '',
            country: '',
            countryShort: '',
            zipCode: '',
          };

          if (location !== null) {
            billingInformations = { ...billingInformations, ...location };
          }

          billingInformations = { ...billingInformations, firstName, lastName };

          this.#store.dispatch(setBillingInfos({ billingInformations }));
        },
      });
  };

  readonly initPurchasableArticles = () => {
    selectIfDefined(this.#store, selectProfile)
      .pipe(
        filter((profile: Profile) =>
          profile?.legalStatus === LegalStatusEnum.ADULT_LEGAL_GUARDIAN
            ? Boolean(profile?.children?.[0]?.planGroupId)
            : Boolean(profile?.planGroupId)
        )
      )
      .subscribe({
        next: (profile: Profile) => {
          const isGuardian = profile.legalStatus === LegalStatusEnum.ADULT_LEGAL_GUARDIAN;
          const planGroupId = isGuardian ? profile?.children?.[0]?.planGroupId : profile.planGroupId;
          this.#store.dispatch(setPurchasableArticlesFromPlanGroupId({ planGroupId }));
        },
      });
  };
}
