import { TargetEnum } from '@loyalty-v3/libs';
import { trackEvent } from '@wizbii/utils/tracking';

export type MenuEntry = {
  label: string;
  trackingLabel: `[menu] ${string}` | `[menu-rl] ${string}`;
  icon?: string;
  route?: string[] | string;
  target?: TargetEnum;
  children?: MenuEntry[];
  isInternalLink?: boolean;
};

export function menuTrackingLabel(entryRoute: string[] | string): string {
  return Array.isArray(entryRoute) ? entryRoute.join('/') : entryRoute;
}

export function trackEntry(entryRoute: string[] | string | undefined, trackingCategory: string) {
  if (!entryRoute) {
    return;
  }
  trackEvent(trackingCategory, 'click', menuTrackingLabel(entryRoute));
}
