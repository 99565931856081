import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { CTA } from '@loyalty-v3/libs';
import { BenefitConfiguration } from '@models/services/service';

export interface HighlightedServiceDto {
  serviceId: string;
  title: string;
  description: string;
  pictureUrl: string;
  serviceName: string;
  serviceLogoUrl: string;
  callToAction: CTA;
  benefitConfiguration: BenefitConfiguration | null;
}

@Injectable({ providedIn: 'root' })
export class HighlightedServicesWebservice {
  readonly #httpClient = inject(HttpClient);
  readonly #baseUrl = `${environment.api.obendy}`;

  readonly getHighlightedServices = (userId: string) =>
    this.#httpClient.get<HighlightedServiceDto[]>(`${this.#baseUrl}/v2/loyalty-profile/${userId}/highlighted-services`);
}
