import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, untracked } from '@angular/core';
import { FreshdeskButtonService } from '@domains/freshdesk/freshdesk-button/freshdesk-button.service';
import { Store } from '@ngrx/store';
import { hasFreshDeskWidgetFeatureFlag } from '@stores/feature-flags/feature-flags.selectors';
import { freshdeskWidgetId } from '@stores/partner/partner.selectors';
import { isLogged } from '@wizbii-utils/angular/stores';

@Component({
  selector: 'app-freshdesk-button',
  standalone: true,
  imports: [AsyncPipe],
  providers: [FreshdeskButtonService],
  template: `
    <!-- eslint-disable @angular-eslint/template/no-call-expression -->

    @if (service.widgetIsMounted()) {
      <button
        class="obendy-freshdesk-button"
        (click)="service.toggleWidget()"
        type="button"
        aria-label="Ouvrir la liste de questions fréquentes"
      >
        &nbsp;
      </button>
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreshdeskButtonComponent {
  readonly #store = inject(Store);
  readonly service = inject(FreshdeskButtonService);

  readonly #widgetId = this.#store.selectSignal(freshdeskWidgetId);
  readonly #userLogged = this.#store.selectSignal(isLogged);
  readonly #hasFreshDeskWidgetFeatureFlag = this.#store.selectSignal(hasFreshDeskWidgetFeatureFlag);

  constructor() {
    effect(() => {
      const userIsLogged = this.#userLogged();

      if (!userIsLogged) {
        return;
      }

      const widgetId = this.#widgetId();

      if (typeof widgetId !== 'number') {
        return;
      }

      if (this.#hasFreshDeskWidgetFeatureFlag() === false) {
        return;
      }

      untracked(() => {
        this.service.initialize(widgetId);
      });
    });

    effect(() => {
      const widgetIsMounted = this.service.widgetIsMounted();
      if (!widgetIsMounted) {
        return;
      }

      this.service.toggleFreshworksWidget(this.service.widgetIsOpen());
    });
  }
}
