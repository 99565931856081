import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { LegalMention } from '@models/legals/legals.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LegalPageWebservice {
  readonly #http = inject(HttpClient);

  readonly #url = environment.api.obendy;

  getLegalPages(partnerId: string): Observable<LegalMention[]> {
    return this.#http.get<LegalMention[]>(`${this.#url}/v3/partner/${partnerId}/legal-pages/public`);
  }
}
