import { inject } from '@angular/core';
import { Theme } from '@models/themes/theme';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  init,
  setServiceBankingProduct,
  setServiceBankingProductFull,
} from '@stores/banking-products/banking-products.actions';
import { hasCompletedOnboarding } from '@stores/profile/profile.selectors';
import { selectThemes } from '@stores/themes/themes.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { UpsellWebService } from '@webservices/upsell.webservice';
import { userId } from '@wizbii-utils/angular/stores';
import { combineLatest, filter, map, switchMap, take } from 'rxjs';

export const loadBankingProducts = createEffect(
  (actions$ = inject(Actions), upsellWebService = inject(UpsellWebService), store = inject(Store)) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => combineLatest([selectIfDefined(store, userId), selectIfDefined(store, hasCompletedOnboarding)])),
      filter(([_, hasCompletedOnboardingP]) => hasCompletedOnboardingP),
      switchMap(([userId]) => upsellWebService.getBankingProducts(userId)),
      map((bankingProducts) => init({ bankingProducts }))
    ),
  { functional: true }
);

export const setServiceBankingProductEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(setServiceBankingProduct),
      switchMap(({ serviceId }) =>
        store.select(selectThemes).pipe(
          filter((themes): themes is Theme[] => !!themes && themes.length > 0),
          take(1),
          map((themes) => setServiceBankingProductFull({ themes, serviceId }))
        )
      )
    ),
  { functional: true }
);
