import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { partnerId } from '@stores/partner/partner.selectors';
import { Credentials } from '@wizbii-utils/angular/algolia';
import { Observable, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlgoliaCredentialsService {
  readonly #httpClient = inject(HttpClient);
  readonly #store = inject(Store);

  getCredentialsFromPartner(): Observable<Credentials> {
    return this.#store
      .select(partnerId)
      .pipe(
        switchMap((id) =>
          this.#httpClient.get<Credentials>(`${environment.api.obendy}/v3/_credentials/partner/${id}/algolia`)
        )
      );
  }
}
