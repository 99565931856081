import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationsService } from '@core/services/notifications.service';
import { FEATURES_ROUTING } from '@features/features.routing';
import { EmailPreferenceOption, LegalStatusEnum } from '@loyalty-v3/libs';
import { Child } from '@models/legal-guardian/child';
import { NotificationType } from '@models/notification/notification';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { updateChildren } from '@stores/children-infos/children-infos.actions';
import { crmFolderId } from '@stores/partner/partner.selectors';
import {
  acceptNewCGU,
  acceptNewCGUFailed,
  acceptNewCGUSuccess,
  deleteA,
  deleteASuccess,
  init,
  profilePut,
  profilePutSuccess,
  profileUpdate,
  refreshAfterPayement,
  refreshAfterPayementSuccess,
  updateSuccess,
} from '@stores/profile/profile.actions';
import { profileId, selectProfile } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { CustomerV3WebService } from '@webservices/customer/customer.webservice';
import { EmailPreferenceV3WebService } from '@webservices/email-preference/email-preference.webservice';
import { ProfileV3WebService } from '@webservices/profile/profile.webservice';
import { SubscriptionWebService } from '@webservices/subscription.webservice';
import { jwtDelete, userId } from '@wizbii-utils/angular/stores';
import { EMPTY, catchError, combineLatest, filter, map, of, switchMap, take } from 'rxjs';

export const loadProfile = createEffect(
  (
    actions$ = inject(Actions),
    profileWebService = inject(ProfileV3WebService),
    store = inject(Store),
    emailPreferenceWebService = inject(EmailPreferenceV3WebService),
    subscriptionWebService = inject(SubscriptionWebService)
  ) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => selectIfDefined(store, userId)),
      switchMap((userId) => profileWebService.get(userId)),
      switchMap((profile) => {
        const profileId = profile?.id;
        const folderId = store.selectSignal(crmFolderId)();

        return combineLatest([
          of(profile),
          emailPreferenceWebService.get(profileId),
          folderId ? subscriptionWebService.getFoldersFor(folderId) : of([]),
        ]);
      }),
      map(([profile, userFolder, partnerFolder]) => {
        const unsubscribedFolders = userFolder?.folders ?? [];
        const isSubscribedToAll = Array.isArray(unsubscribedFolders);

        const emailCampaigns: EmailPreferenceOption[] = partnerFolder.map((folder) => {
          const hasSubscriptions = Boolean(
            !isSubscribedToAll && folder.id && unsubscribedFolders[folder.id] === undefined
          );

          return {
            id: folder.id,
            title: folder.publicInformations?.fr_FR?.name ?? folder.name,
            description: folder.publicInformations?.fr_FR?.description,
            subscribed: isSubscribedToAll || hasSubscriptions,
          };
        });

        return { profile, shouldShowNotification: emailCampaigns.length !== 0 };
      }),
      map(({ profile, shouldShowNotification }) => init({ profile, shouldShowNotification }))
    ),
  { functional: true }
);

export const updateEffect = createEffect(
  (actions$ = inject(Actions), profileWebService = inject(ProfileV3WebService), store = inject(Store)) =>
    actions$.pipe(
      ofType(profileUpdate),
      switchMap(() => selectIfDefined(store, userId)),
      switchMap((userId) => profileWebService.get(userId)),
      map((profile) => updateSuccess({ profile }))
    ),
  { functional: true }
);

export const refreshAfterPayementAdultEffect = createEffect(
  (actions$ = inject(Actions), profileWebService = inject(ProfileV3WebService), store = inject(Store)) =>
    actions$.pipe(
      ofType(refreshAfterPayement),
      switchMap(() => selectIfDefined(store, profileId)),
      switchMap((userId) => profileWebService.get(userId)),
      map((profile) => refreshAfterPayementSuccess({ profile }))
    ),
  { functional: true }
);

export const refreshAfterPayementChildrenEffect = createEffect(
  (actions$ = inject(Actions), profileWebService = inject(ProfileV3WebService), store = inject(Store)) =>
    actions$.pipe(
      ofType(refreshAfterPayement),
      switchMap(() => selectIfDefined(store, selectProfile)),
      switchMap((profile) => {
        if (profile.legalStatus === LegalStatusEnum.ADULT_LEGAL_GUARDIAN) {
          return profileWebService.getChildren(profile.id).pipe(
            map(({ body }) => body),
            filter((children): children is Child[] => !!children)
          );
        }

        return of([]);
      }),
      map((children) => updateChildren({ children }))
    ),
  { functional: true }
);

export const deleteEffect = createEffect(
  (actions$ = inject(Actions), customerWebService = inject(CustomerV3WebService), store = inject(Store)) =>
    actions$.pipe(
      ofType(deleteA),
      switchMap(() => selectIfDefined(store, profileId)),
      switchMap((id) => customerWebService.delete(id)),
      map(() => {
        store.dispatch(jwtDelete());
      }),
      map(() => deleteASuccess())
    ),
  { functional: true }
);

export const acceptNewCGUEffect = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    profileWebService = inject(ProfileV3WebService),
    router = inject(Router),
    notificationsService = inject(NotificationsService)
  ) => {
    return actions$.pipe(
      ofType(acceptNewCGU),
      switchMap(() => selectIfDefined(store, userId)),
      switchMap((userId) => profileWebService.patchNewCGUConsent(userId)),
      map(() => {
        router.navigate([FEATURES_ROUTING.dashboard]);
        return acceptNewCGUSuccess();
      }),
      catchError(() => {
        notificationsService.createNotification({
          title: "Une erreur s'est produite",
          text: 'Veuillez contacter le support',
          type: NotificationType.danger,
          date: Date.now(),
        });
        acceptNewCGUFailed();
        return EMPTY;
      })
    );
  },
  { functional: true }
);

export const profilePutEffect = createEffect(
  (actions$ = inject(Actions), profileWebService = inject(ProfileV3WebService), store = inject(Store)) =>
    actions$.pipe(
      ofType(profilePut),
      switchMap(({ profile }) =>
        store.select(selectProfile).pipe(
          switchMap((stateProfile) => {
            if (stateProfile === null) {
              throw new Error('No profile set');
            }

            return profileWebService.put({ ...(stateProfile ?? {}), ...profile });
          }),
          take(1),
          map((profile) => profilePutSuccess({ profile }))
        )
      )
    ),
  { functional: true }
);
