import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Theme } from '@models/themes/theme';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemesWebService {
  readonly #http = inject(HttpClient);

  readonly #baseUrl = environment.api.obendy;

  getThemesFromPlanGroupId(planGroupId: string): Observable<Theme[]> {
    return this.#http.get<Theme[]>(`${this.#baseUrl}/v1/plan-groups/${planGroupId}/themes`);
  }
}
