import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  QueryList,
  ViewChildren,
  inject,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuModule } from '@angular/material/menu';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { LoyaltyIcons } from '@core/utils/icons-utils/icons-list';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Breadcrumb } from '@models/breadcrumbs/breadcrumbs.interface';
import { Store } from '@ngrx/store';
import { breadcrumbs } from '@stores/breadcrumbs/breadcrumbs.selectors';
import { homepageUrl, productName } from '@stores/partner/partner.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { isLogged } from '@wizbii-utils/angular/stores';
import { trackEvent } from '@wizbii/utils/tracking';
import { NgStringPipesModule } from 'ngx-pipes';
import { Observable, combineLatest, delay, map, take, tap } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    MatIconModule,
    NgStringPipesModule,
    MatMenuModule,
    RouterLink,
    RouterLinkActive,
  ],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlapTrigger: false,
        xPosition: 'after',
        yPosition: 'below',
        backdropClass: 'cdk-overlay-transparent-backdrop',
        overlayPanelClass: 'breadcrumbs-mobile-menu-panel',
      },
    },
  ],
})
export class BreadcrumbsComponent implements AfterViewChecked {
  readonly #store = inject(Store);

  readonly #productName$ = selectIfDefined(this.#store, productName);
  readonly #homepageUrl$ = selectIfDefined(this.#store, homepageUrl);
  readonly #breadcrumbs$ = selectIfDefined(this.#store, breadcrumbs);
  readonly #mobileMoreBreadcrumb$ = this.#breadcrumbs$.pipe(
    map((breadcrumbs) => breadcrumbs.filter((_, index) => breadcrumbs.length > 1 && index < breadcrumbs.length - 1))
  );
  readonly #isUserLogged$ = this.#store.select(isLogged);

  readonly HomeIcon = LoyaltyIcons.HomeAlt;
  readonly CloseIcon = LoyaltyIcons.Close;
  readonly ChevronRight = LoyaltyIcons.ChevronRight;
  readonly More = LoyaltyIcons.More;

  @ViewChildren('lastBreadcrumb', { read: ElementRef }) lastBreadcrumbElement?: QueryList<ElementRef>;
  readonly showMoreButton = signal(false);

  ngAfterViewChecked() {
    this.lastBreadcrumbElement?.changes
      .pipe(
        take(1),
        tap(() => this.showMoreButton.set(false)),
        delay(0)
      )
      .subscribe({
        next: (element: QueryList<ElementRef>) => this.checkIfElementIsOverflowing(element.first.nativeElement),
      });
  }

  checkIfElementIsOverflowing(element: HTMLElement): void {
    this.showMoreButton.set(element.scrollHeight > element.clientHeight);
  }

  readonly vm$: Observable<{
    breadcrumbs: Breadcrumb[];
    mobileMoreBreadcrumb: Breadcrumb[];
    productName: string;
    isHomeColorized: boolean;
    routerLink: string[] | null;
    href: string | null;
  }> = combineLatest([
    this.#breadcrumbs$,
    this.#mobileMoreBreadcrumb$,
    this.#productName$,
    this.#isUserLogged$,
    this.#homepageUrl$,
  ]).pipe(
    map(([breadcrumbs, mobileMoreBreadcrumb, productName, isUserLogged, homepageUrl]) => ({
      breadcrumbs,
      mobileMoreBreadcrumb,
      productName,
      isHomeColorized: breadcrumbs.length === 0,
      routerLink: isUserLogged ? ['/', FEATURES_ROUTING.dashboard] : null,
      href: isUserLogged ? null : `https://${homepageUrl}`,
    }))
  );

  trackClickEvent(label: string, value?: string) {
    trackEvent("fil d'ariane", 'click', `${label} - ${value}`);
  }
}
