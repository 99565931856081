import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { LoyaltyIcons } from '@core/utils/icons-utils';
import { environment } from '@environment';
import { TargetEnum } from '@loyalty-v3/libs';
import { Observable } from 'rxjs';

export type MenuItem = {
  label: string;
  link: {
    url: string;
    target: TargetEnum;
  };
  children: MenuItem[];
};

type MenuIcon =
  | LoyaltyIcons.BrokenLink
  | LoyaltyIcons.Category
  | LoyaltyIcons.EmptyWallet
  | LoyaltyIcons.Flag
  | LoyaltyIcons.Home
  | LoyaltyIcons.House
  | LoyaltyIcons.Message
  | LoyaltyIcons.Note
  | LoyaltyIcons.Premium
  | LoyaltyIcons.Search
  | LoyaltyIcons.Share
  | LoyaltyIcons.SignPost
  | LoyaltyIcons.Sparkles;

export type MobileMainMenuItem = MenuItem & { icon: MenuIcon };

export interface DesktopMenu {
  mainNavigation: MenuItem[];
  secondaryNavigation: MenuItem[];
}

export interface MobileMenu {
  mainNavigation: MobileMainMenuItem[];
  secondaryNavigation: MenuItem[];
}

export interface MainMenu {
  desktopMenu: DesktopMenu;
  mobileMenu: MobileMenu;
}

@Injectable({ providedIn: 'root' })
export class MenuWebService {
  readonly #http = inject(HttpClient);
  readonly #baseObendyUrl = environment.api.obendy;

  readonly getMenu = (): Observable<MainMenu> => this.#http.get<MainMenu>(`${this.#baseObendyUrl}/v1/menu`);
}
