import { inject } from '@angular/core';
import { BASKET_INFOS, BASKET_PAYMENT, BASKET_PREVIEW } from '@models/payment/payment-steps';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  goToStep,
  init,
  noSaveBasket,
  paymentSuccess,
  saveBasket,
  saveBasketSuccess,
  setPurchasableArticlesFromPlanGroupId,
  setPurchasableArticlesFromPlanGroupIdSuccess,
} from '@stores/payment/payment.actions';
import { basket } from '@stores/payment/payment.selectors';
import { refreshAfterPayement } from '@stores/profile/profile.actions';
import { PaymentWebservice } from '@webservices/payment.webservice';
import { UpsellWebService } from '@webservices/upsell.webservice';
import { isLogged } from '@wizbii-utils/angular/stores';
import { filter, map, of, switchMap } from 'rxjs';

export const loadPayment = createEffect(
  (actions$ = inject(Actions), paymentWebservice = inject(PaymentWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => store.select(isLogged)),
      filter((isLogged) => isLogged),
      switchMap(() => paymentWebservice.getBasket()),
      map(
        (remoteBasket) =>
          remoteBasket?.articles.map((item) => ({
            articleId: item.id,
            customerId: item.metadata.customerId,
            customerFullName: null,
          })) ?? null
      ),
      map((basket) =>
        init({
          basket,
          paymentStep: basket ? BASKET_INFOS : BASKET_PREVIEW,
          paymentInitiated: !!basket,
        })
      )
    ),
  { functional: true }
);

export const saveBasketEffect = createEffect(
  (actions$ = inject(Actions), paymentWebservice = inject(PaymentWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(saveBasket),
      switchMap(() => store.select(basket)),
      switchMap((_basket) => {
        if (!_basket || _basket.length === 0) {
          return of(noSaveBasket());
        }

        return paymentWebservice.saveBasket(_basket).pipe(map(() => saveBasketSuccess()));
      })
    ),
  { functional: true }
);

export const saveBasketSuccessEffect = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(saveBasketSuccess),
      map(() => goToStep({ paymentStep: BASKET_PAYMENT }))
    ),
  { functional: true }
);

export const paymentSuccessEffect = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(paymentSuccess),
      map(() => refreshAfterPayement())
    ),
  { functional: true }
);

export const setPurchasableArticlesFromPlanGroupIdEffect = createEffect(
  (actions$ = inject(Actions), upsellWebService = inject(UpsellWebService)) =>
    actions$.pipe(
      ofType(setPurchasableArticlesFromPlanGroupId),
      switchMap(({ planGroupId }) => upsellWebService.getPurchasableArticles(planGroupId)),
      map((purchasableArticles) => setPurchasableArticlesFromPlanGroupIdSuccess({ purchasableArticles }))
    ),
  { functional: true }
);
