import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { NPS } from '@models/nps/nps';
import { Store } from '@ngrx/store';
import { partnerId } from '@stores/partner/partner.selectors';
import { Observable, distinctUntilChanged, filter, map, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NpsWebservice {
  readonly #store = inject(Store);
  readonly #http = inject(HttpClient);

  readonly #partnerId$ = this.#store.select(partnerId).pipe(
    filter((partnerId): partnerId is string => !!partnerId),
    distinctUntilChanged()
  );

  readonly shouldDisplayNps = (customerId: string): Observable<NPS> =>
    this.#partnerId$.pipe(
      map((partnerId) => `${environment.api.obendy}/v1/partner/${partnerId}/customer/${customerId}/nps`),
      switchMap((npsApi) => this.#http.get<NPS>(npsApi))
    );

  readonly markNpsAsClosed = (customerId: string): Observable<void> =>
    this.#partnerId$.pipe(
      switchMap((partnerId) =>
        this.#http.put<void>(`${environment.api.obendy}/v1/partner/${partnerId}/customer/${customerId}/nps-close`, null)
      )
    );
}
