import { Injectable, Type, inject } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Resolve, ResolveFn, Router } from '@angular/router';
import { Breadcrumb } from '@models/breadcrumbs/breadcrumbs.interface';
import { Store } from '@ngrx/store';
import { update } from '@stores/breadcrumbs/breadcrumbs.actions';
import { TitleStrategyData } from '@stores/breadcrumbs/breadcrumbs.reducer';
import { titleStrategyData } from '@stores/breadcrumbs/breadcrumbs.selectors';
import { Observable, combineLatest, distinctUntilChanged, filter, map, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BreadcrumbCoreService {
  readonly #router = inject(Router);
  readonly #store = inject(Store);

  readonly initBreadcrumbs = () => {
    combineLatest([this.#router.events, this.#store.select(titleStrategyData)])
      .pipe(
        filter((combined): combined is [NavigationEnd, TitleStrategyData] => combined[0] instanceof NavigationEnd),
        switchMap(([_, titleStrategyDataD]) =>
          combineLatest(
            this.#getBreadcrumbsFromRouterState(this.#router.routerState.root, [], titleStrategyDataD)
          ).pipe(map((breadcrumbs) => breadcrumbs.filter((breadcrumb): breadcrumb is Breadcrumb => !!breadcrumb)))
        ),
        distinctUntilChanged()
      )
      .subscribe({
        next: (breadcrumbs) => {
          this.#store.dispatch(update({ breadcrumbs }));
        },
      });
  };

  #getBreadcrumbsFromRouterState(
    route: ActivatedRoute,
    breadcrumbs: Observable<Breadcrumb | undefined>[],
    titleStrategyData: TitleStrategyData
  ): Observable<Breadcrumb | undefined>[] {
    const currentBreadcrumb = combineLatest([this.#getEmptyPathChildTitle(route), route.url, ...breadcrumbs]).pipe(
      map(([titleFromEmptyPathChild, urlSegments, ...breadcrumbs]) => {
        const currentBreadcrumbs = breadcrumbs.filter((breadcrumb): breadcrumb is Breadcrumb => !!breadcrumb);
        const parentBreadcrumb = currentBreadcrumbs[(currentBreadcrumbs.length || 1) - 1] ?? undefined;
        let label = route.snapshot.title ?? titleFromEmptyPathChild;
        const segmentsPath = urlSegments.map((segment) => segment.path);

        if (titleStrategyData && segmentsPath.find((path) => titleStrategyData.urlPath === path)) {
          label = titleStrategyData.title;
        }

        if (label && !currentBreadcrumbs.find((breadcrumb) => breadcrumb?.label === label)) {
          return {
            label: label,
            route: [
              ...(parentBreadcrumb?.route.filter((route) => !!route) ?? []),
              ...urlSegments.map((segment) => segment.path),
            ],
          };
        }

        return undefined;
      })
    );

    breadcrumbs.push(currentBreadcrumb);

    if (route.children.length) {
      this.#getBreadcrumbsFromRouterState(route.children[0], breadcrumbs, titleStrategyData);
    }
    return breadcrumbs;
  }

  readonly #getEmptyPathChildTitle = (route: ActivatedRoute): Observable<string> => {
    const title = route.routeConfig?.children?.find((childRoute) => {
      return childRoute.path === '';
    })?.title;

    return this.#getTitle(title, route.snapshot);
  };

  readonly #getTitle = (
    title: string | Type<Resolve<string>> | ResolveFn<string> | undefined,
    routeSnapshot: ActivatedRouteSnapshot
  ): Observable<string> => {
    if (!title) {
      return of('');
    }

    if (typeof title === 'string') {
      return of(title);
    }

    const resolvedTitle = (title as ResolveFn<string>)(routeSnapshot, this.#router.routerState.snapshot);

    if (typeof resolvedTitle === 'string') {
      return of(resolvedTitle);
    }

    return resolvedTitle as Observable<string>;
  };
}
