import { Injectable } from '@angular/core';
import { TargetEnum } from '@loyalty-v3/libs';
import { Partner } from '@models/partner/partner.interface';
import { LOGIN_PASSWORD, REDIRECT_BUTTON } from '@models/partner/sign-in-method/sign-in-method.type';

@Injectable()
export class LoginSigninButtonsService {
  readonly #hasSignMethod = (partner: Partner | null) =>
    !partner?.partnerConfiguration.signInMethod.includes(LOGIN_PASSWORD) &&
    partner?.partnerConfiguration.signInMethod.includes(REDIRECT_BUTTON) &&
    partner?.partnerConfiguration.signInRedirectButtonLink;

  readonly getSigninCta = (partner: Partner | null) => {
    if (this.#hasSignMethod(partner) || !partner?.partnerConfiguration?.signInCta) {
      return null;
    }

    return {
      ...partner.partnerConfiguration.signInCta,
      target: partner.partnerConfiguration.signInCta?.target ?? TargetEnum.self,
    };
  };

  readonly getSignupCta = (partner: Partner | null) => {
    if (this.#hasSignMethod(partner) || !partner?.partnerConfiguration?.signUpCta) {
      return null;
    }

    return {
      ...partner.partnerConfiguration.signUpCta,
      target: partner.partnerConfiguration.signUpCta?.target ?? TargetEnum.self,
    };
  };
}
