import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { PlanGroup } from '@loyalty-v3/libs';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PlanWebService {
  readonly #http = inject(HttpClient);

  readonly #baseUrl = environment.api.obendy;

  getPlanGroup(planGroupId: string): Observable<PlanGroup> {
    return this.#http.get<PlanGroup>(`${this.#baseUrl}/v3/plan-groups/${planGroupId}`);
  }
}
