import { inject } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { init } from '@stores/feature-flags/feature-flags.actions';
import { partnerId } from '@stores/partner/partner.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { FeatureFlagsWebservice } from '@webservices/feature-flags/feature-flags.webservice';
import { map, switchMap } from 'rxjs';

export const loadFeatureFlags = createEffect(
  (actions$ = inject(Actions), featureFlagsWebservice = inject(FeatureFlagsWebservice), store = inject(Store)) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => selectIfDefined(store, partnerId)),
      switchMap((id) => featureFlagsWebservice.get(id)),
      map((featureFlags) => init({ featureFlags }))
    ),
  { functional: true }
);
