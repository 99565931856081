import { inject } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { loadChildrenSuccess } from '@stores/children-infos/children-infos.actions';
import { childrenInfos } from '@stores/children-infos/children-infos.selector';
import { init, loadChildrenPlanGroupsSuccess } from '@stores/plan/plan.actions';
import { ProfileState } from '@stores/profile/profile.reducer';
import { selectProfile } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { PlanWebService } from '@webservices/plan.webservice';
import { combineLatest, filter, forkJoin, map, of, switchMap } from 'rxjs';

export const loadPlan = createEffect(
  (actions$ = inject(Actions), planWebservice = inject(PlanWebService), store = inject(Store)) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => selectIfDefined(store, selectProfile)),
      filter(
        (profile): profile is ProfileState & { planGroupId: string } => profile !== null && profile.planGroupId !== null
      ),
      switchMap(({ planGroupId }) => planWebservice.getPlanGroup(planGroupId)),
      switchMap((userPlanGroup) => {
        const relatedPlanGroupId = userPlanGroup?.relatedGroupId;

        return combineLatest([
          of(userPlanGroup),
          relatedPlanGroupId ? planWebservice.getPlanGroup(relatedPlanGroupId) : of(undefined),
        ]);
      }),
      map(([userPlanGroup, relatedPlanGroup]) => init({ planGroups: { userPlanGroup, relatedPlanGroup } }))
    ),
  { functional: true }
);

export const loadChildrenPlanGroups$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), planWebservice = inject(PlanWebService)) => {
    return actions$.pipe(
      ofType(loadChildrenSuccess),
      switchMap(() => store.select(childrenInfos)),
      map((children) => [...new Set(children.map((child) => child.planGroupId))]),
      switchMap((childrenIds) => forkJoin(childrenIds.map((id) => planWebservice.getPlanGroup(id)))),
      map((planGroups) => loadChildrenPlanGroupsSuccess({ planGroups }))
    );
  },
  { functional: true }
);
