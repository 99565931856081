import { Breadcrumb } from '@models/breadcrumbs/breadcrumbs.interface';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { BreadcrumbsState, breadcrumbsFeatureKey } from '@stores/breadcrumbs/breadcrumbs.reducer';

const selectBreadcrumbs = createFeatureSelector<BreadcrumbsState>(breadcrumbsFeatureKey);

export const breadcrumbs: MemoizedSelector<BreadcrumbsState, Breadcrumb[]> = createSelector(
  selectBreadcrumbs,
  (state) => state.breadcrumbs
);
export const titleStrategyData = createSelector(selectBreadcrumbs, (state) => state?.titleStrategyData);
