<!-- eslint-disable @angular-eslint/template/no-call-expression -->
@if (vm(); as notification) {
  <div class="notification-toast" aria-live="assertive" tabindex="-1">
    <div class="notification-toast__content">
      <header class="notification-toast__content__header" role="banner">
        <mat-icon class="notification-toast__icon" [svgIcon]="notification.icon" />
        <p obendy-text-bold-font>{{ notification.title }}</p>
        <button
          class="notification-toast__content__header__close-button"
          (click)="close(notification.id)"
          #closeButton
          obendy-icon-button
          type="button"
          appearance="icon"
          aria-label="Fermer la notification"
        >
          <mat-icon class="notification-toast__close-button__icon" [svgIcon]="notification.closeIcon" />
        </button>
      </header>
      <p obendy-text-font>{{ notification.description }}</p>

      @if (notification.pastTime) {
        <p class="notification-toast__time" obendy-text-bold-font>
          {{ notification.pastTime }}
        </p>
      }
    </div>
  </div>
}
