import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { AuthenticationV3WebService } from '@webservices/authentication/authentication.web-service';
import { PLATFORM_WEBSERVICES_TOKEN, PlatformWebservicesToken } from '@webservices/injection-tokens';
import { JwtServiceInterface, JwtTokens } from '@wizbii-utils/angular/jwt';
import { jwtDelete, jwtSet, selectJwt } from '@wizbii-utils/angular/stores';
import { Observable, map } from 'rxjs';

@Injectable()
export class JwtService implements JwtServiceInterface {
  readonly #store = inject(Store);
  readonly #authenticationWebservice = inject(AuthenticationV3WebService);
  readonly #loyaltyWebservicesToken: PlatformWebservicesToken = inject(PLATFORM_WEBSERVICES_TOKEN);

  blacklistRoutes: (string | RegExp)[] = [
    'https://storage.googleapis.com',
    new RegExp(
      `//(auth|d).${this.#loyaltyWebservicesToken.platformDomain}(?!(/v1/(user|auth)).*/(email|overview|password|login-token))`,
      'i'
    ),
    'https://labonnealternance.apprentissage.beta.gouv.fr/',
    ...Object.keys(environment.strapi).map((key) => (environment.strapi as any)[key]),
  ];

  getTokens(): Observable<JwtTokens | undefined> {
    return this.#store.select(selectJwt).pipe(map((state) => state ?? undefined));
  }

  logout(): void {
    this.#store.dispatch(jwtDelete());
  }

  refreshToken(tokens: JwtTokens): Observable<JwtTokens> {
    return this.#authenticationWebservice.fromRefreshToken(tokens);
  }

  saveLocalTokens(jwtTokens: JwtTokens): void {
    this.#store.dispatch(jwtSet({ jwtTokens }));
  }
}
