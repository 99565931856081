import { inject } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { initializeCommunications } from '@stores/communications/communications.actions';
import { hasCompletedOnboarding } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { CommunicationWebservice } from '@webservices/communication/communication.webservice';
import { filter, map, switchMap } from 'rxjs';

export const loadCommunications = createEffect(
  (
    actions$ = inject(Actions),
    communicationWebservice = inject(CommunicationWebservice),
    store = inject(Store),
    CommunicationsPageHeader = 'X-Communication-Page-Activated'
  ) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => selectIfDefined(store, hasCompletedOnboarding)),
      filter((hasCompletedOnboarding) => hasCompletedOnboarding),
      switchMap(() => communicationWebservice.isCommunicationPageActive()),
      map(({ headers }) => headers.get(CommunicationsPageHeader)),
      // Convert the header value to a boolean for the application state.
      // Note: The header content is always a string; it's never a boolean.
      // We compare the header string to 'true' to interpret it as a boolean.
      map((isActive) => initializeCommunications({ isCommunicationPageActive: isActive === 'true' }))
    ),
  { functional: true }
);
