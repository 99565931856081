import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouteReuseStrategy } from '@angular/router';
import { DetachedRouteHandleExt } from '@core/route-strategy/detached-route-handle-ext';

@Injectable()
export class WizbiiRouteReuseStrategy implements RouteReuseStrategy {
  /**
   * 5 minutes
   */
  readonly #time = 1000 * 60 * 5;

  readonly #handlers = new Map<
    Route,
    {
      handle: DetachedRouteHandleExt | null;
      time: number;
    }
  >();

  /**
   * The shouldDetach is called when we leave the current route.
   * Here is where we put the logic for selecting which routes we want to be cached.
   * @param route
   * @returns
   */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!route.data.shouldReuse;
  }

  /**
   * The store method is called only if the shouldDetach method returned true.
   * This is where we put the logic for storing the routed components.
   * @param route
   * @param handle
   * @returns
   */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandleExt | null): void {
    if (!route.routeConfig || !route.data.shouldReuse) {
      return;
    }

    this.#handlers.set(route.routeConfig, { handle, time: Date.now() });
  }

  /**
   * The shouldAttach method is called when we visit a new route.
   * If this method returns true, the retrieve method is called.
   * Otherwise, the component is created from scratch.
   * @param route
   * @returns
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const routeConfig = route.routeConfig;

    if (!routeConfig) {
      return false;
    }

    const handle = this.#handlers.get(routeConfig);

    const time = route.data.reuseTime ?? this.#time;

    if (handle?.time && handle.time + time < Date.now()) {
      handle.handle?.componentRef.destroy();
      this.#handlers.delete(routeConfig);
      return false;
    }

    return !!handle;
  }

  /**
   * The retrieve method is called if shouldAttach returned true.
   * It provides the current route we just landed as a parameter.
   * This is where we put the logic for retrieving any stored RouteHandle to reuse.
   * If null is returned, the call has no effect.
   * @param route
   * @returns
   */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandleExt | null {
    if (!route.routeConfig) {
      return null;
    }

    return this.#handlers.get(route.routeConfig)?.handle ?? null;
  }

  /**
   * The shouldReuseRoute method is called every time we navigate from one route to another.
   * If it returns true, the routing won’t happen.
   * If it returns false, the routing happens and the rest of the methods are executed.
   * @param future
   * @param curr
   * @returns
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
