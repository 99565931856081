import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild,
  computed,
  effect,
  input,
  output,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { createTimeDiff, defaultTimeDiffGenerator } from '@commons/time-past/time-diff';
import { parseInputValue } from '@commons/time-past/time-past';
import { LoyaltyIcons } from '@core/utils/icons-utils';
import {
  NotificationConfig,
  NotificationIcons,
  NotificationPalette,
  NotificationType,
} from '@models/notification/notification';
import { derivedAsync } from 'ngxtension/derived-async';
import { hostBinding } from 'ngxtension/host-binding';

@Component({
  selector: 'app-notification-toast',
  standalone: true,
  templateUrl: './notification-toast.component.html',
  styleUrls: ['./notification-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatProgressBarModule],
})
export class NotificationToastComponent {
  readonly notificationConfig = input.required<NotificationConfig>();
  readonly dismissNotification = output<number>();

  @ViewChild('closeButton') closeButton?: ElementRef<HTMLElement>;

  readonly computedStatusColorSig = hostBinding(
    'style.--status-color',
    computed(() => {
      const notification = this.notificationConfig();
      if (notification === null) return NotificationPalette[NotificationType.primary];
      return NotificationPalette[notification.type];
    })
  );

  constructor() {
    const initEffect = effect(() => {
      if (this.vm() && this.closeButton) {
        this.closeButton?.nativeElement.focus();
        initEffect.destroy();
      }
    });
  }

  readonly close = (notificationId: number): void => {
    this.dismissNotification.emit(notificationId);
  };

  readonly #pastTime = derivedAsync(() => {
    const date = this.notificationConfig().date;
    if (!date) return;

    return defaultTimeDiffGenerator(createTimeDiff(parseInputValue(date)));
  });

  readonly vm = computed(() => {
    const notification = this.notificationConfig();

    const pastTime = this.#pastTime();
    if (notification.date && pastTime === undefined) return undefined;

    return {
      id: notification.id,
      title: notification.title,
      icon: NotificationIcons[notification.type],
      closeIcon: LoyaltyIcons.Close,
      description: notification.text,
      pastTime,
    };
  });
}
