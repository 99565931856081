import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MenuEntry, trackEntry } from '@commons/main-menu/service/entries';
import { LinkTargetPipe } from '@commons/pipes/links-target-blank/links-target-blank.pipe';
import { LoyaltyIcons } from '@core/utils/icons-utils';
import { CoPlatformMainMenuCardComponent } from '@domains/burger-menu/co-platform-main-menu-card/co-platform-main-menu-card.component';
import { IncentiveBannerComponent } from '@domains/incentive-banner/incentive-banner.component';
import { Store } from '@ngrx/store';
import { hasCoPlatform } from '@stores/partner/partner.selectors';
import { jwtDelete } from '@wizbii-utils/angular/stores';
import { trackEvent } from '@wizbii/utils/tracking';

@Component({
  selector: 'app-desktop-menu-button',
  standalone: true,
  imports: [
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    RouterLink,
    RouterLinkActive,
    NgClass,
    MatIconModule,
    IncentiveBannerComponent,
    CoPlatformMainMenuCardComponent,
    LinkTargetPipe,
  ],
  template: `<!-- eslint-disable @angular-eslint/template/no-call-expression -->
    <button
      class="hover:tw-rounded-lg hover:tw-bg-light-gray"
      [cdkMenuTriggerFor]="menu"
      [ngClass]="{
        'tw-rounded-lg tw-bg-border-light': isOpen(),
        'tw-p-0': mainMenuEntry(),
        'tw-p-2': !mainMenuEntry(),
      }"
      (cdkMenuOpened)="open()"
      (cdkMenuClosed)="close()"
      type="button"
    >
      <span class="visually-hidden">Ouvrir le menu</span>
      @if (mainMenuEntry(); as mainMenuEntry) {
        <span class="tw-text-base tw-font-medium tw-text-black hover:tw-font-semibold hover:tw-text-primary">
          {{ mainMenuEntry.label }}
        </span>
      } @else {
        <mat-icon [svgIcon]="burgerMenuIcon" />
      }
    </button>

    <ng-template #menu>
      <ul
        class="tw-mt-2 tw-flex tw-min-w-[15.125rem] tw-max-w-[19.625rem] tw-flex-col tw-gap-2 tw-rounded-2xl tw-bg-white tw-p-2 tw-shadow-[0_8px_16px_0_rgba(0,0,0,0.24)]"
        cdkMenu
      >
        @for (entry of childrenEntries(); track $index) {
          <li>
            @if (entry.isInternalLink) {
              <a
                class="tw-block tw-w-full tw-rounded tw-p-2 tw-font-medium tw-text-black tw-no-underline hover:tw-bg-border-light"
                [routerLink]="entry.route"
                (click)="trackEntry(entry.route, trackingCategory())"
                routerLinkActive="tw-font-semibold tw-text-primary"
                cdkMenuItem
              >
                {{ entry.label }}
              </a>
            } @else {
              <a
                class="tw-block tw-w-full tw-rounded tw-p-2 tw-font-medium tw-text-black tw-no-underline hover:tw-bg-border-light"
                [href]="entry.route"
                [target]="entry.target | linkTarget"
                (click)="trackEntry(entry.route, trackingCategory())"
                routerLinkActive="tw-font-semibold tw-text-primary"
                cdkMenuItem
              >
                {{ entry.label }}
              </a>
            }
            @if (entry.children && entry.children.length > 0) {
              <ul class="tw-flex tw-flex-col tw-gap-2 tw-pl-4 tw-pt-2">
                @for (child of entry.children; track $index) {
                  <li>
                    @if (child.isInternalLink) {
                      <a
                        class="tw-block tw-w-full tw-rounded tw-p-2 tw-font-medium tw-text-black tw-no-underline hover:tw-bg-border-light"
                        [routerLink]="child.route"
                        (click)="trackEntry(child.route, trackingCategory())"
                        routerLinkActive="tw-font-semibold tw-text-primary"
                        cdkMenuItem
                      >
                        {{ child.label }}
                      </a>
                    } @else {
                      <a
                        class="tw-block tw-w-full tw-rounded tw-p-2 tw-font-medium tw-text-black tw-no-underline hover:tw-bg-border-light"
                        [href]="child.route"
                        [target]="child.target | linkTarget"
                        (click)="trackEntry(child.route, trackingCategory())"
                        routerLinkActive="tw-font-semibold tw-text-primary"
                        cdkMenuItem
                      >
                        {{ child.label }}
                      </a>
                    }
                  </li>
                }
              </ul>
            }
          </li>
        }
        @if (!mainMenuEntry()) {
          <li>
            <button
              class="tw-w-full tw-rounded tw-p-2 tw-text-start tw-font-medium tw-text-black hover:tw-bg-border-light"
              (click)="logout()"
              type="button"
              cdkMenuItem
            >
              Se déconnecter
            </button>
          </li>
        }
        @if (hasCoPlatform()) {
          <li>
            <app-co-platform-main-menu-card />
          </li>
        }
      </ul>
    </ng-template> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopMenuButtonComponent {
  protected readonly isOpen = signal(false);
  readonly childrenEntries = input.required<MenuEntry[]>();
  readonly mainMenuEntry = input<MenuEntry | null>(null);

  readonly hasCoPlatform = inject(Store).selectSignal(hasCoPlatform);
  readonly trackingCategory = computed(() => {
    const isMainMenu = !!this.mainMenuEntry();

    return isMainMenu ? 'menu-principal-desktop' : 'menu-secondaire-desktop';
  });

  readonly burgerMenuIcon = LoyaltyIcons.BurgerMenu;
  readonly #store = inject(Store);

  protected readonly open = () => this.isOpen.set(true);
  protected readonly close = () => this.isOpen.set(false);

  protected readonly logout = () => {
    trackEvent(this.trackingCategory(), 'click ', 'déconnecter');
    this.#store.dispatch(jwtDelete());
  };

  protected readonly trackEntry = trackEntry;
}
