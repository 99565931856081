import { inject } from '@angular/core';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { init } from '@stores/services/services.actions';
import { getServices } from '@stores/services/services.utils';
import { ServicesV3WebService } from '@webservices/services/services.webservice';
import { isLogged } from '@wizbii-utils/angular/stores';
import { filter, map, switchMap } from 'rxjs';

export const loadProfile = createEffect(
  (actions$ = inject(Actions), servicesWebservice = inject(ServicesV3WebService), store = inject(Store)) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => store.select(isLogged)),
      filter((isLogged) => isLogged),
      switchMap(() => servicesWebservice.getServices()),
      switchMap((services) => getServices(services, store)),
      map((state) => init({ state }))
    ),
  { functional: true }
);
