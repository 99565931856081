import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment';
import { FeatureFlag } from '@models/feature-flag/feature-flag';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsWebservice {
  readonly #http = inject(HttpClient);
  readonly #url = environment.api.obendy;

  get(partnerId: string): Observable<FeatureFlag[]> {
    return this.#http.get<FeatureFlag[]>(`${this.#url}/v1/partner/${partnerId}/feature-flags`);
  }
}
