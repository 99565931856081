import { InjectionToken } from '@angular/core';

export interface PlatformWebservicesToken {
  platformDomain: string;
}

export const PLATFORM_WEBSERVICES_TOKEN = new InjectionToken<PlatformWebservicesToken>('Platform Webservices Token', {
  providedIn: 'root',
  factory: () => ({
    platformDomain: '',
  }),
});
