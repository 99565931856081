import { ImageLoader } from '@angular/common';
import { environment } from '@environment';
import { ImaginaryService } from '@wizbii/utils';

const imaginaryService = new ImaginaryService(environment.api.imaginary);

export const imaginaryLoader: ImageLoader = (config) => {
  let { src, width, isPlaceholder } = config;

  if (!src) {
    return src;
  }

  if (src.endsWith('.svg')) {
    return src;
  }

  if (isPlaceholder) {
    return getPlaceholderImg(src, width);
  }

  return getFinalImg(src, width);
};

const getImg = (convertImg: (src: string, width?: number) => string, src: string, width?: number): string => {
  if (!src.startsWith('/')) {
    return convertImg(src, width);
  }

  if (environment.platform === 'local') {
    return src;
  }

  return convertImg(`${window.origin}${src}`, width);
};

const getPlaceholderImg = (src: string, width?: number): string => getImg(getBlurredImg, src, width);

const getFinalImg = (src: string, width?: number): string => getImg(getConvertedImg, src, width);

const getConvertedImg = (src: string, width?: number): string => {
  if (!width) {
    return imaginaryService.convert(src, { quality: 100, stripmeta: true, interlace: true } as any);
  }

  return imaginaryService.resize(src, {
    width,
    quality: 100,
    stripmeta: true,
    interlace: true,
  } as any);
};

const getBlurredImg = (src: string, width?: number): string => {
  if (!width) {
    return imaginaryService.convert(src, { quality: 1, stripmeta: true, interlace: true, sigma: 30.0 } as any);
  }

  return imaginaryService.resize(src, {
    width,
    quality: 1,
    stripmeta: true,
    interlace: true,
    sigma: 30.0,
  } as any);
};
