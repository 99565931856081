import { inject } from '@angular/core';
import { LegalStatusEnum } from '@loyalty-v3/libs';
import { Actions, ROOT_EFFECTS_INIT, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CHILDREN_ACTIONS, init, loadChildrenSuccess } from '@stores/children-infos/children-infos.actions';
import * as FromProfileSelectors from '@stores/profile/profile.selectors';
import { profileId } from '@stores/profile/profile.selectors';
import { selectIfDefined } from '@stores/utils/selects.operators';
import { ProfileV3WebService } from '@webservices/profile/profile.webservice';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs';

export const init$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => store.select(FromProfileSelectors.selectProfile)),
      filter((profile) => profile?.legalStatus === LegalStatusEnum.ADULT_LEGAL_GUARDIAN),
      map(() => init())
    ),
  { functional: true }
);

export const loadChildren$ = createEffect(
  (actions$ = inject(Actions), profileV3Webservice = inject(ProfileV3WebService), store = inject(Store)) =>
    actions$.pipe(
      ofType(CHILDREN_ACTIONS.INIT_CHILDREN_STATE),
      switchMap(() => selectIfDefined(store, profileId)),
      distinctUntilChanged(),
      switchMap((profileId) => profileV3Webservice.getChildren(profileId)),
      map((response) => ({
        children: response.body ?? [],
        allowChildCreation: response.headers.get('X-Allow-Child-Creation') === 'true',
      })),
      map(({ children, allowChildCreation }) => loadChildrenSuccess({ children, allowChildCreation }))
    ),
  { functional: true }
);
