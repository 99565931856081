import { inject } from '@angular/core';
import { FEATURES_ROUTING } from '@features/features.routing';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { homepageUrl } from '@stores/partner/partner.selectors';
import { selectUrl } from '@stores/router/router.selectors';
import { jwtDeleteSuccess } from '@wizbii-utils/angular/stores';
import { filter, switchMap, tap } from 'rxjs';

export const logoutJwtEffect = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(jwtDeleteSuccess),
      switchMap(() => store.select(selectUrl)),
      filter((currentRoute) => !!currentRoute && !currentRoute.includes(FEATURES_ROUTING.onboarding)),
      switchMap(() => store.select(homepageUrl)),
      tap((homepageUrl) => {
        window.open(`https://${homepageUrl}`, '_self', 'noreferrer noopener');
      })
    ),
  { functional: true, dispatch: false }
);
