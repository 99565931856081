import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ServiceAuthorizationState,
  serviceAuthorizationFeatureKey,
} from '@stores/service-authorization/authorization.reducer';

export const selectServiceAuthorization =
  createFeatureSelector<ServiceAuthorizationState>(serviceAuthorizationFeatureKey);

export const serviceAuthorizationContext = createSelector(selectServiceAuthorization, (state) => state.context);
