import { AngularRoutingProvider } from '@bugsnag/angular-performance';
import { BrowserConfiguration } from '@bugsnag/browser-performance';
import { BrowserConfig } from '@bugsnag/js';
import { environment } from '@environment';

const includedMessages = [
  '0 Unknown Error',
  '0 undefined',
  'Failed to fetch',
  'Permissions check failed',
  'Tab ID must be defined to get tab content',
  '[WIZBII NPS] the user already answered this survey',
  'The request is not allowed by the user agent or the platform',
  'The operation is not supported',
];

const errorStatusIgnored = [403, 404];

export const bugsnagConfig: BrowserConfig = {
  apiKey: environment.bugsnag.key,
  appVersion: environment.version,
  autoTrackSessions: true,
  releaseStage: environment.platform,
  enabledReleaseStages: ['prod', 'staging', 'dev', 'qa-01'],
  generateAnonymousId: true,
  onError: (event) => {
    if (errorStatusIgnored.includes(event.originalError.status)) {
      return false;
    }

    if (includedMessages.some((message) => event.originalError.message.toLowerCase().includes(message.toLowerCase()))) {
      return false;
    }

    if (event.originalError.status === 401) {
      if (
        event.originalError.message.toLowerCase().includes('/_api/obendy-api/v1/services/wizbii-drive/'.toLowerCase())
      ) {
        return false;
      }
    }

    if (event.originalError.name === 'TypeError') {
      // Errors from widgets or to web-metrics-api failed fetch requests due to network failures
      if (event.originalError.message === 'NetworkError when attempting to fetch resource') {
        return false;
      }

      // Errors from failed fetch requests of consent and web metrics API
      const requestEventId = (event.breadcrumbs ?? []).findIndex((breadcrumb) => breadcrumb?.type === 'request');
      const requestEvent = (event.breadcrumbs ?? [])?.[requestEventId];
      if (
        requestEvent &&
        requestEvent.message === 'fetch() error' &&
        (requestEvent.metadata?.['request']?.includes('_api/web-metrics-api') ||
          requestEvent.metadata?.['request']?.includes(`https://consent.${environment.apiDomain}/v2/consent/`))
      ) {
        return false;
      }
    }

    return true;
  },
};

export const bugsnagPerformanceConfig: BrowserConfiguration = {
  apiKey: environment.bugsnag.key,
  appVersion: environment.version,
  releaseStage: environment.platform,
  routingProvider: new AngularRoutingProvider(),
  enabledReleaseStages: ['prod'],
};
