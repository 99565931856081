import { Breadcrumb } from '@models/breadcrumbs/breadcrumbs.interface';
import { createReducer, on } from '@ngrx/store';
import { setTitleStrategyData, update } from '@stores/breadcrumbs/breadcrumbs.actions';

export const breadcrumbsFeatureKey = 'breadcrumbs';

export type TitleStrategyData = { urlPath: string; title: string } | null;

export type BreadcrumbsState = {
  breadcrumbs: Breadcrumb[];
  titleStrategyData: TitleStrategyData;
};

const initialState: BreadcrumbsState = {
  breadcrumbs: [] as Breadcrumb[],
  titleStrategyData: null,
};

export const breadcrumbReducer = createReducer(
  initialState,
  on(update, (state, { breadcrumbs }) => ({ ...state, breadcrumbs })),
  on(setTitleStrategyData, (state, { titleStrategyData }) => ({ ...state, titleStrategyData }))
);
