import { AtLeast } from '@loyalty-v3/libs';
import { Basket, BasketItem } from '@models/payment/basket';
import { BillingInformations } from '@models/payment/billing-informations';
import { PaymentStep } from '@models/payment/payment-steps';
import { PurchasableArticle } from '@models/payment/purchasable-article';
import { createAction, props } from '@ngrx/store';

export const init = createAction(
  '[Payment] Init',
  props<{
    paymentInitiated: boolean;
    basket: Basket | null;
    paymentStep: PaymentStep;
  }>()
);
export const setInitialStep = createAction('[Payment] set initial step');
export const initPayment = createAction('[Payment] Init payment');
export const setBillingInfos = createAction(
  '[Payment] Set billing informations',
  props<{ billingInformations: BillingInformations }>()
);
export const addItemToBasket = createAction('[Payment]  Add Item to Basket', props<{ basketItem: BasketItem }>());
export const removeItemFromBasket = createAction(
  '[Payment] Remove Item from Basket',
  props<{ basketItem: AtLeast<BasketItem, 'articleId' | 'customerId'> }>()
);
export const saveBasket = createAction('[Payment] Save basket');
export const saveBasketSuccess = createAction('[Payment] Save basket success');
export const noSaveBasket = createAction('[Payment] No save basket success');
export const setPurchasableArticle = createAction(
  '[Payment] Set purchasable article',
  props<{ purchasableArticle: PurchasableArticle }>()
);
export const paymentSuccess = createAction('[Payment] Payment Success');
export const paymentFailed = createAction('[Payment] Payment Failed');
export const paymentReset = createAction('[Payment] Payment Reset');
export const goToStep = createAction('[Payment] Go to basket next step', props<{ paymentStep: PaymentStep }>());
export const emptyBasket = createAction('[Payment] Empty basket');
export const setPurchasableArticlesFromPlanGroupId = createAction(
  '[Payment] Set purchasable article from plan group id',
  props<{ planGroupId: string }>()
);
export const setPurchasableArticlesFromPlanGroupIdSuccess = createAction(
  '[Payment] Set purchasable article from plan group id success',
  props<{ purchasableArticles: PurchasableArticle[] }>()
);
